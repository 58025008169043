<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <g id="freepik--Floor--inject-8">
      <ellipse
        id="freepik--floor--inject-8"
        cx="250"
        cy="346.54"
        rx="222.67"
        ry="128.63"
        style="fill:#f5f5f5"
      ></ellipse>
    </g>
    <g id="freepik--Shadows--inject-8">
      <path
        id="freepik--Shadow--inject-8"
        d="M438.45,332.2c-5.78-2.08-19.62-4.12-42.75-5.8-30.4-2.21-38.21-3.8-40.06,2.62L321,322.34c.25-8.13-5-16.31-15.74-22.51-21-12.13-55.07-12.13-76.08,0s-21,31.8,0,43.93c12.23,7.06,28.88,10,44.81,8.84l24.45,28.22s-3.65,2.26,1,8.61c5,6.82,30.46,41.32,34.39,44.71,6.19,5.34,18.13,9.16,34.8-.32,19.06-10.84-.56-21-11.71-28.65-13.48-9.26-42.43-28.95-46.09-31.62-2.79-2-5.64.79-5.64.79l-20.77-23.11a67.91,67.91,0,0,0,20.86-7.47c8.05-4.65,13-10.4,14.88-16.41l34.07,6.79s-1.56,3,5.65,5c6.17,1.67,52.54,13.47,60.81,12.46,8.06-1,14.56-5.7,18.63-10.4C444.3,335.4,441.29,333.23,438.45,332.2Z"
        style="fill:#e0e0e0"
      ></path>
      <g id="freepik--shadows--inject-8">
        <path
          d="M155.52,409.4a3.86,3.86,0,0,1-3.73-3.08l-.87-4.56a50.84,50.84,0,0,1-5.78-1.42H145l-8,3.72a4.49,4.49,0,0,1-1.88.4,4.61,4.61,0,0,1-2.27-.58l-8-4.62a3.25,3.25,0,0,1-1.66-2.64,3.2,3.2,0,0,1,1.39-2.78l4.85-3.49a18.77,18.77,0,0,1-1.22-1.83l-10.2-.65a3.65,3.65,0,0,1-3.42-3.64v-6a3.65,3.65,0,0,1,3.42-3.64l10.2-.65a18.77,18.77,0,0,1,1.22-1.83l-4.85-3.49a3.17,3.17,0,0,1,.27-5.42l8-4.62a4.73,4.73,0,0,1,4.15-.19l8,3.73h.11s0,0,0,0a51.55,51.55,0,0,1,5.85-1.44l.87-4.56a3.83,3.83,0,0,1,3.73-3.08h11.77a3.83,3.83,0,0,1,3.72,3.08l.87,4.56a50.84,50.84,0,0,1,5.78,1.42h.15l8-3.73a4.75,4.75,0,0,1,4.16.19l8,4.62a3.17,3.17,0,0,1,.27,5.42l-4.84,3.49a20.64,20.64,0,0,1,1.21,1.83l10.2.65a3.65,3.65,0,0,1,3.42,3.64v6a3.65,3.65,0,0,1-3.42,3.64l-10.2.65a20.64,20.64,0,0,1-1.21,1.83l4.84,3.48a3.18,3.18,0,0,1-.27,5.43l-8,4.62a4.68,4.68,0,0,1-2.28.58,4.57,4.57,0,0,1-1.87-.39l-8-3.73h-.08a51.55,51.55,0,0,1-5.85,1.44l-.87,4.56a3.84,3.84,0,0,1-3.72,3.08Zm5.88-36.6a24.9,24.9,0,0,0-12.23,2.88c-2.74,1.59-4.31,3.62-4.31,5.57s1.57,4,4.31,5.57a27.32,27.32,0,0,0,24.46,0c2.74-1.58,4.31-3.61,4.31-5.57s-1.57-4-4.31-5.57A24.9,24.9,0,0,0,161.4,372.8Z"
          style="fill:#e0e0e0"
        ></path>
        <path
          d="M70.79,365.73a3.42,3.42,0,0,1-.79-.09l-9-2.14a3.14,3.14,0,0,1-2.14-1.73,3.31,3.31,0,0,1,.07-2.91l1.36-2.7c-.83-.45-1.61-.93-2.35-1.43l-8.06,1.35a4,4,0,0,1-.7.06,3.84,3.84,0,0,1-3.12-1.46l-3.49-4.86-.75-.45.14-2.1.1-.19a2.81,2.81,0,0,1,.26-.62l.07-.11a3.62,3.62,0,0,1,1.94-1.46l6.22-2c0-.2,0-.41,0-.61l-6.42-2a3.09,3.09,0,0,1-2.1-2.37l-.06-.16v-.42a3.52,3.52,0,0,1,.67-2l3.24-4.51a4.23,4.23,0,0,1,3.3-1.72,3.66,3.66,0,0,1,.57.05L58,330.51h0c.55-.38,1.17-.77,1.83-1.15l.48-.27-1.46-2.88a3,3,0,0,1-.32-1.39,3.3,3.3,0,0,1,2.66-3.1l8.61-2.06a4.28,4.28,0,0,1,1-.12,3.91,3.91,0,0,1,2.84,1.12l3.73,3.93c.82,0,1.69-.07,2.55-.07s1.8,0,2.71.08l3.53-3.72a4.44,4.44,0,0,1,3.1-1.35,3.31,3.31,0,0,1,.83.1l8.93,2.13a3.13,3.13,0,0,1,2.42,2.68l0,.13v.36a3.41,3.41,0,0,1-.39,1.5l-1.34,2.66.43.24a22,22,0,0,1,1.9,1.2l8.07-1.36a4.23,4.23,0,0,1,.7-.06,3.81,3.81,0,0,1,3.13,1.47l3.57,5a3,3,0,0,1,.56,1.73v.17a3.32,3.32,0,0,1-2.44,2.91l-6.18,2v.61l6.4,2a3.06,3.06,0,0,1,2.17,2.94,3.51,3.51,0,0,1-.64,2l-3.27,4.56a4.18,4.18,0,0,1-3.29,1.72,3.81,3.81,0,0,1-.59-.05l-8.19-1.38h0a26.06,26.06,0,0,1-2.32,1.43l1.45,2.88a3,3,0,0,1,0,2.62,3.46,3.46,0,0,1-2.36,1.87l-8.64,2.06a4.27,4.27,0,0,1-1,.13,3.87,3.87,0,0,1-2.83-1.12l-3.75-3.94c-.83,0-1.72.07-2.6.07s-1.77,0-2.65-.07l-3.53,3.71A4.4,4.4,0,0,1,70.79,365.73ZM68,340.23a2.65,2.65,0,0,0-.21.26c-.1.12-.17.23-.24.33l-.16.27a3,3,0,0,0,.27,3.5,7.13,7.13,0,0,0,1.56,1.54,10.34,10.34,0,0,0,1.1.71,20.46,20.46,0,0,0,9.79,2.28,23.49,23.49,0,0,0,5.41-.62,16.79,16.79,0,0,0,4.36-1.66,11.44,11.44,0,0,0,1.06-.68,7,7,0,0,0,1.78-1.84v0l.22-.38.09-.22a3,3,0,0,0-.51-3c-1.94-2.71-6.94-4.53-12.44-4.53a23.49,23.49,0,0,0-5.41.62,13.51,13.51,0,0,0-6.41,3.2Z"
          style="fill:#e0e0e0"
        ></path>
      </g>
    </g>
    <g id="freepik--Gears--inject-8">
      <g id="freepik--gears--inject-8">
        <path
          d="M205.67,369.57V364.8a1.08,1.08,0,0,0-1-1.06l-7.6-.48V352.6h0a.64.64,0,0,0-.37-.58l-8-4.62a2.11,2.11,0,0,0-1.77-.08l-8.11,3.76a3,3,0,0,1-1.86.11,47.06,47.06,0,0,0-6.3-1.5,1.53,1.53,0,0,1-1.17-1.14l-1-5.35a1.25,1.25,0,0,0-1.18-1H155.52a1.25,1.25,0,0,0-1.19,1l-1,5.35a1.52,1.52,0,0,1-1.17,1.14,47.43,47.43,0,0,0-6.3,1.5,2.93,2.93,0,0,1-1.85-.12l-8.12-3.75a2.14,2.14,0,0,0-1.77.07l-8,4.63a.66.66,0,0,0-.37.57v10.67l-7.59.48a1.08,1.08,0,0,0-1,1.06v6h0v4.17h0v5.95h0c0,.13.1.23.14.34a1,1,0,0,0,.16.38,1.1,1.1,0,0,0,.51.24c.07,0,.11.09.19.09h.14l7.45.47h0v6.14h0v4.56h0s0,0,0,0a.56.56,0,0,0,.06.21s0,0,0,0a.81.81,0,0,0,.28.27l8,4.62h0a1.69,1.69,0,0,0,.77.2h.21a1.31,1.31,0,0,0,.28,0v-.05a1.86,1.86,0,0,0,.49-.08l8.12-3.76a2.93,2.93,0,0,1,1.85-.11,49.44,49.44,0,0,0,6.3,1.5,1.53,1.53,0,0,1,1.18,1.14l1,5.34a1.07,1.07,0,0,0,.2.32v.13c0,.06.11.09.16.14a.7.7,0,0,0,.16.14,1.11,1.11,0,0,0,.67.25h11.77a1.18,1.18,0,0,0,.74-.27h0a1.19,1.19,0,0,0,.44-.7l1-5.34a1.52,1.52,0,0,1,1.17-1.14,48.65,48.65,0,0,0,6.3-1.5,3,3,0,0,1,1.86.11l8.11,3.76a1.87,1.87,0,0,0,.76.14h0a2,2,0,0,0,1-.21l8-4.62a.65.65,0,0,0,.37-.55h0v-10.7l7.6-.48a1.07,1.07,0,0,0,1-1.06l0-11.11A.76.76,0,0,0,205.67,369.57Zm-29.18,5a16.86,16.86,0,0,1-1.56,1c-7.47,4.31-19.58,4.31-27.05,0a15.79,15.79,0,0,1-1.57-1c.48-.34,1-.67,1.57-1,7.47-4.31,19.58-4.31,27.05,0C175.49,373.89,176,374.22,176.49,374.56Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M205.7,369.78l0,11.11a1.08,1.08,0,0,1-1,1.06l-7.6.48v10.7a.65.65,0,0,1-.37.55l-8,4.62a2,2,0,0,1-1,.21h0a1.87,1.87,0,0,1-.76-.14l-8.11-3.76a2.23,2.23,0,0,0-.76-.18h0a2.81,2.81,0,0,0-1.1.08,47,47,0,0,1-6.3,1.49,1.51,1.51,0,0,0-.49.21v.06a1.41,1.41,0,0,0-.68.88l-1,5.34a1.18,1.18,0,0,1-.43.7h0a1.23,1.23,0,0,1-.75.27H155.52l-.22,0a1.22,1.22,0,0,1-.77-.5v-.13c-.07-.11-.18-.19-.2-.32l-1-5.34a1.41,1.41,0,0,0-.48-.77v0a1.52,1.52,0,0,0-.7-.36,47.78,47.78,0,0,1-6.3-1.49,2.82,2.82,0,0,0-1.24-.06v0a2.73,2.73,0,0,0-.61.14l-8.12,3.76a1.79,1.79,0,0,1-.49.08v.05h0a2,2,0,0,1-1.26-.21h0l-8-4.62a1.26,1.26,0,0,1-.17-.13.91.91,0,0,1-.13-.16.67.67,0,0,1-.07-.24h0v-10.7l-1.39-.1-6.07-.38h-.14a1.08,1.08,0,0,1-1-1V370.76a1.08,1.08,0,0,0,1,1.06l10.67.67a2,2,0,0,1,1.45,1,16,16,0,0,0,2.26,3.15.83.83,0,0,1-.12,1.29L126,382.46a.59.59,0,0,0,0,1.08l8,4.63a2.14,2.14,0,0,0,1.77.07l8.11-3.75a2.9,2.9,0,0,1,1.86-.11,49,49,0,0,0,6.3,1.49,1.56,1.56,0,0,1,1.18,1.14l1,5.35a1.27,1.27,0,0,0,1.19,1h11.77a1.25,1.25,0,0,0,1.18-1l1-5.35a1.55,1.55,0,0,1,1.17-1.14,47.06,47.06,0,0,0,6.3-1.5,3,3,0,0,1,1.86.12l8.11,3.75a2.14,2.14,0,0,0,1.77-.07l8-4.63a.59.59,0,0,0,0-1.08l-6.34-4.57a.84.84,0,0,1-.12-1.3,16.15,16.15,0,0,0,2.27-3.14,2,2,0,0,1,1.44-1l10.67-.67a1.08,1.08,0,0,0,1-1.06v-1.19A.76.76,0,0,1,205.7,369.78Z"
          style="fill:#263238"
        ></path>
        <path
          d="M197.08,352.6v10.66l-3.07-.19a2,2,0,0,1-1.44-1A15.86,15.86,0,0,0,190.3,359a.85.85,0,0,1,.12-1.31l6.34-4.56a.69.69,0,0,0,.32-.5Z"
          style="fill:#263238"
        ></path>
        <path
          d="M132.5,359a15.4,15.4,0,0,0-2.26,3.14,2,2,0,0,1-1.45,1l-3.06.19V352.58a.67.67,0,0,0,.32.52l6.34,4.56A.85.85,0,0,1,132.5,359Z"
          style="fill:#263238"
        ></path>
        <path
          d="M134.1,347.39l-8,4.63a.59.59,0,0,0,0,1.08l6.34,4.57a.84.84,0,0,1,.12,1.3,15.4,15.4,0,0,0-2.26,3.14,2,2,0,0,1-1.45,1l-10.66.67a1.08,1.08,0,0,0-1,1.06v6a1.08,1.08,0,0,0,1,1.06l10.66.67a2,2,0,0,1,1.45,1,15.4,15.4,0,0,0,2.26,3.14.85.85,0,0,1-.12,1.31L126,382.46a.6.6,0,0,0,0,1.08l8,4.63a2.14,2.14,0,0,0,1.77.07l8.12-3.75a2.94,2.94,0,0,1,1.85-.12,47.43,47.43,0,0,0,6.3,1.5,1.52,1.52,0,0,1,1.17,1.14l1,5.35a1.25,1.25,0,0,0,1.19,1h11.77a1.25,1.25,0,0,0,1.18-1l1-5.35a1.53,1.53,0,0,1,1.17-1.14,47.06,47.06,0,0,0,6.3-1.5,3,3,0,0,1,1.86.12l8.11,3.75a2.14,2.14,0,0,0,1.77-.07l8-4.63a.59.59,0,0,0,0-1.08l-6.34-4.56a.85.85,0,0,1-.12-1.31,15.86,15.86,0,0,0,2.27-3.14,2,2,0,0,1,1.44-1l10.67-.67a1.08,1.08,0,0,0,1-1.06v-6a1.08,1.08,0,0,0-1-1.06L194,363.07a2,2,0,0,1-1.44-1A15.86,15.86,0,0,0,190.3,359a.84.84,0,0,1,.12-1.3l6.34-4.57a.59.59,0,0,0,0-1.08l-8-4.62a2.11,2.11,0,0,0-1.77-.08l-8.11,3.76a3,3,0,0,1-1.86.11,47.06,47.06,0,0,0-6.3-1.5,1.53,1.53,0,0,1-1.17-1.14l-1-5.35a1.25,1.25,0,0,0-1.18-1H155.52a1.25,1.25,0,0,0-1.19,1l-1,5.35a1.52,1.52,0,0,1-1.17,1.14,47.43,47.43,0,0,0-6.3,1.5,2.93,2.93,0,0,1-1.85-.12l-8.12-3.75A2.14,2.14,0,0,0,134.1,347.39ZM174.93,360c7.46,4.31,7.46,11.31,0,15.62s-19.58,4.31-27.05,0-7.47-11.31,0-15.62S167.46,355.66,174.93,360Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M125.72,383a.67.67,0,0,1,.32-.53l3.19-2.3V372.6a1.36,1.36,0,0,0-.44-.11l-10.67-.67a1.08,1.08,0,0,1-1-1.06v10.15a1.08,1.08,0,0,0,1,1h.14l6.07.38,1.39.1Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M144.6,384.32a2.66,2.66,0,0,0-.62.17l-8.11,3.75a2.08,2.08,0,0,1-1,.14v10.13a2.44,2.44,0,0,0,.54,0h0v-.05a1.79,1.79,0,0,0,.49-.08l8.12-3.76a2.49,2.49,0,0,1,.61-.14Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M154.48,392.74a1.37,1.37,0,0,1-.15-.38l-1-5.35a1.59,1.59,0,0,0-.16-.41v10.15a1.4,1.4,0,0,1,.16.4l1,5.34a.68.68,0,0,0,.15.26Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M169.65,386.61a1.24,1.24,0,0,0-.16.4l-1,5.35a1,1,0,0,1-.15.38v10.13a1,1,0,0,0,.15-.38l1-5.34a.82.82,0,0,1,.16-.37Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M188,388.37a2,2,0,0,1-1-.13l-8.11-3.75a2.58,2.58,0,0,0-.61-.17v10.14a2.18,2.18,0,0,1,.61.16l8.11,3.76a1.87,1.87,0,0,0,.76.14H188Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M204.68,371.82l-10.67.67a1.36,1.36,0,0,0-.44.11v7.56l3.19,2.3a.67.67,0,0,1,.32.54v-.57l7.6-.48a1.08,1.08,0,0,0,1-1.06l0-11.11a.76.76,0,0,0,0-.21v1.19A1.08,1.08,0,0,1,204.68,371.82Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M44.54,334.49c0,.46,0,8.84,0,9.07a.4.4,0,0,0,.08.23l3.56,5a1.45,1.45,0,0,0,1.31.38l8.21-1.38a2.27,2.27,0,0,1,1.48.25,21.82,21.82,0,0,0,2,1.27s0,6.67,0,6.81a.59.59,0,0,0,.46.5l8.95,2.14A1.71,1.71,0,0,0,72,358.2l3.69-3.89a2.23,2.23,0,0,1,1.51-.65,45.64,45.64,0,0,0,5.61,0,1.92,1.92,0,0,1,1.35.42l4.12,4.33a1.59,1.59,0,0,0,1.39.26l8.61-2.05c.42-.1.69-.37.67-.65v-6.72c.59-.34,1.15-.69,1.68-1.06a2.64,2.64,0,0,1,1.62-.46l8.48,1.42a1.56,1.56,0,0,0,1.34-.62l3.24-4.53a.82.82,0,0,0,.18-.52c0-.16,0-2.82,0-6.54a.37.37,0,0,0,.07-.2c0-.08,0-12.16,0-12.91v0a.4.4,0,0,0-.08-.22l-3.56-5a1.48,1.48,0,0,0-1.31-.38l-8.21,1.39a2.4,2.4,0,0,1-1.48-.25,21.88,21.88,0,0,0-2-1.28c0-3.11,0-6.31,0-6.68,0,0,0,0,0,0a.58.58,0,0,0-.46-.59l-8.95-2.14a1.71,1.71,0,0,0-1.44.53l-3.68,3.88a2.25,2.25,0,0,1-1.51.66,43.75,43.75,0,0,0-5.61,0,2,2,0,0,1-1.35-.42L71.8,309a1.65,1.65,0,0,0-1.39-.26l-8.61,2.06c-.42.1-.68.36-.67.63h0v6.74c-.58.34-1.15.7-1.68,1.06a2.65,2.65,0,0,1-1.62.47l-8.48-1.43a1.57,1.57,0,0,0-1.34.63l-3.24,4.52a.9.9,0,0,0-.18.5v0c0,.4,0,6.74,0,10.39h0a.39.39,0,0,0-.06.2h0Zm46.58,1.12a19.13,19.13,0,0,1-5.05,1.93c-6,1.44-12.71.56-17.07-1.92a12.22,12.22,0,0,1-1.36-.88c.39-.27.81-.54,1.26-.8,6.14-3.55,16.1-3.55,22.25,0,.46.27.89.54,1.28.81A12.9,12.9,0,0,1,91.12,335.61Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M97,317.08c.69.32,1.35.66,2,1v-6.68a.93.93,0,0,1-.1.34L96.68,316A.78.78,0,0,0,97,317.08Z"
          style="fill:#263238"
        ></path>
        <path
          d="M106.82,327.7a8.83,8.83,0,0,1,0,2.81.92.92,0,0,0,.57,1l7.75,2.44a.51.51,0,0,1,.36.5c0,.15,0,2.51,0,2.51a.39.39,0,0,0,.06-.18c0-4.4,0-11.75,0-12.39v-.54c0,.23-.25.46-.63.58l-7.31,2.32A1,1,0,0,0,106.82,327.7Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M102,338.65v9.08a1.55,1.55,0,0,1,.31,0l8.48,1.42a.92.92,0,0,0,.43,0v-9.07a1.17,1.17,0,0,1-.45,0l-8.48-1.43A.91.91,0,0,0,102,338.65Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M99,349.26c.58-.34,1.15-.69,1.68-1.06a3,3,0,0,1,1.31-.47v-9.08a2.86,2.86,0,0,0-1.32.48,25.6,25.6,0,0,1-3.44,2c-.5.25-.78.68-.62,1l2.33,4.61a.49.49,0,0,1,0,.19C99,347.19,99,349.26,99,349.26Z"
          style="fill:#263238"
        ></path>
        <path
          d="M83.67,344.72v9.08a1.29,1.29,0,0,1,.52.29l4.12,4.33a1.23,1.23,0,0,0,.75.3v-9.08a1.26,1.26,0,0,1-.77-.29L84.17,345A1.2,1.2,0,0,0,83.67,344.72Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M76.69,344.67v9.08a1.6,1.6,0,0,1,.54-.09,45.64,45.64,0,0,0,5.61,0,2.16,2.16,0,0,1,.83.13v-9.08a2,2,0,0,0-.85-.13,45.48,45.48,0,0,1-5.6,0A1.51,1.51,0,0,0,76.69,344.67Z"
          style="fill:#263238"
        ></path>
        <path
          d="M71.21,349.6v9.08a1.94,1.94,0,0,0,.82-.48l3.69-3.89a2.47,2.47,0,0,1,1-.56v-9.08a2.34,2.34,0,0,0-1,.57L72,349.12A2,2,0,0,1,71.21,349.6Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M61.18,347v2.25h0v3.56c0,1.61,0,3.18,0,3.25a.59.59,0,0,0,.46.5l8.95,2.13a1.18,1.18,0,0,0,.61,0V349.6a1.27,1.27,0,0,1-.63,0l-8.95-2.14A.55.55,0,0,1,61.18,347Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M58.29,338.66v9.08a2.08,2.08,0,0,1,.9.27,21.82,21.82,0,0,0,2,1.27s0-2.08,0-2.36a.87.87,0,0,1,.09-.37l2.15-4.26a.76.76,0,0,0-.27-1.07,25.73,25.73,0,0,1-4-2.28A1.76,1.76,0,0,0,58.29,338.66Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M44.54,334.5c0,.45,0,8.82,0,9.06a.4.4,0,0,0,.08.23l3.57,5a1.07,1.07,0,0,0,.61.35V340a1.09,1.09,0,0,1-.64-.36l-3.56-5A.33.33,0,0,1,44.54,334.5Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M98.46,310.79l-8.95-2.14a1.71,1.71,0,0,0-1.44.53l-3.68,3.88a2.25,2.25,0,0,1-1.51.66,43.75,43.75,0,0,0-5.61,0,2,2,0,0,1-1.35-.42L71.8,309a1.65,1.65,0,0,0-1.39-.26l-8.61,2.06c-.42.1-.68.36-.67.63a.39.39,0,0,0,0,.18l2.33,4.63c.17.32-.11.77-.62,1s-1.19.6-1.75.91-1.15.7-1.68,1.06a2.65,2.65,0,0,1-1.62.47l-8.48-1.43a1.57,1.57,0,0,0-1.34.63l-3.24,4.52a.9.9,0,0,0-.18.5v0a.49.49,0,0,0,.36.47l7.74,2.46a.9.9,0,0,1,.58,1,8.77,8.77,0,0,0,0,2.8c.07.37-.28.8-.8,1l-7.31,2.31a1,1,0,0,0-.56.39h0a.39.39,0,0,0-.06.2v0h0a.36.36,0,0,0,.07.22l3.57,5a1.41,1.41,0,0,0,1.3.38l8.22-1.38a2.19,2.19,0,0,1,1.47.26,25.73,25.73,0,0,0,4,2.28.77.77,0,0,1,.27,1.08l-2.14,4.25a.62.62,0,0,0,.36,1l8.95,2.14a1.74,1.74,0,0,0,1.44-.53l3.69-3.88a2.15,2.15,0,0,1,1.51-.65,47.56,47.56,0,0,0,5.6,0,2,2,0,0,1,1.35.42l4.13,4.34a1.62,1.62,0,0,0,1.38.26l8.61-2.06c.51-.13.8-.49.63-.82l-2.33-4.61c-.16-.33.12-.77.62-1a24.59,24.59,0,0,0,3.44-2,2.54,2.54,0,0,1,1.61-.46l8.48,1.43a1.55,1.55,0,0,0,1.34-.63l3.25-4.52a1,1,0,0,0,.18-.5.49.49,0,0,0-.37-.49l-7.74-2.45a.91.91,0,0,1-.57-1,8.77,8.77,0,0,0,0-2.8,1,1,0,0,1,.8-1l7.31-2.32c.38-.12.61-.35.63-.58v0a.4.4,0,0,0-.08-.22l-3.56-5a1.48,1.48,0,0,0-1.31-.38l-8.21,1.39a2.4,2.4,0,0,1-1.48-.25,21.88,21.88,0,0,0-2-1.28c-.63-.35-1.29-.69-2-1a.78.78,0,0,1-.27-1.08l2.15-4.25a.75.75,0,0,0,.09-.34s0,0,0,0A.58.58,0,0,0,98.46,310.79ZM74,320.76c8-1.93,17.23.28,20.56,4.91a5.58,5.58,0,0,1,.8,5.5,1.08,1.08,0,0,1-.08.19l-.09.19a.76.76,0,0,1-.09.18.08.08,0,0,1,0,0,8.79,8.79,0,0,1-2.69,3,12.9,12.9,0,0,1-1.31.86,19.13,19.13,0,0,1-5.05,1.93c-6,1.44-12.71.56-17.07-1.92a12.22,12.22,0,0,1-1.36-.88,9.92,9.92,0,0,1-2.13-2.11,5.59,5.59,0,0,1-.43-6.28l.08-.14.19-.3c.11-.16.22-.32.34-.47l.31-.39.16-.16.22-.23A15.72,15.72,0,0,1,74,320.76Z"
          style="fill:#455a64"
        ></path>
      </g>
    </g>
    <g id="freepik--Robot--inject-8">
      <g id="freepik--robot--inject-8">
        <path
          d="M364.78,326.84a6.24,6.24,0,0,1-1.13-.1l-63.72-12a6,6,0,1,1,2.24-11.81l63.72,12a6,6,0,0,1-1.11,11.92Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M351.12,325H351c-2.08-.39-3-2.93-2.4-6.3.68-3.66,3.08-7,6.32-6.42a.6.6,0,1,1-.23,1.18c-2.45-.46-4.35,2.47-4.91,5.46-.41,2.15-.13,4.6,1.44,4.9a.6.6,0,0,1-.11,1.19Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M340.51,323h-.11a3,3,0,0,1-2.26-2.13c-.89-2.54,0-6.61,2-8.89a4.32,4.32,0,0,1,4.18-1.7.61.61,0,0,1-.22,1.19,3.14,3.14,0,0,0-3,1.29c-1.66,1.93-2.5,5.61-1.76,7.71a1.81,1.81,0,0,0,1.34,1.35.6.6,0,0,1-.11,1.19Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M329.9,321h-.12c-2.07-.39-3-2.93-2.4-6.31.69-3.65,3.08-7,6.32-6.41a.6.6,0,1,1-.22,1.18c-2.46-.47-4.35,2.47-4.92,5.46-.4,2.15-.13,4.6,1.45,4.89a.62.62,0,0,1,.48.71A.61.61,0,0,1,329.9,321Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M319.28,319h-.11a3,3,0,0,1-2.26-2.14c-.89-2.53,0-6.6,2-8.89a4.32,4.32,0,0,1,4.19-1.69.6.6,0,1,1-.23,1.18,3.18,3.18,0,0,0-3.05,1.3c-1.66,1.93-2.5,5.6-1.76,7.71a1.81,1.81,0,0,0,1.34,1.35.6.6,0,0,1-.11,1.19Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M308.67,317l-.11,0c-2.08-.39-3.05-2.92-2.41-6.3.69-3.65,3.09-7,6.32-6.42a.61.61,0,0,1-.22,1.19c-2.44-.46-4.35,2.46-4.92,5.45-.4,2.15-.12,4.6,1.45,4.9a.6.6,0,0,1,.48.7A.62.62,0,0,1,308.67,317Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M295,308.82a6,6,0,0,0,4.89,5.88l63.72,12a6.24,6.24,0,0,0,1.13.1,6,6,0,0,0,5.9-4.9l0-.21Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M433.42,274.36c-4.76-1.26-12.25-1.65-18.56,3.38-5.14,4.1-11.08,22.59-13.07,29l-33.69-.08h0a8.67,8.67,0,0,0-4.93,2.34c-5.64,5.6-6.71,11.86-6.23,17.82.3,3.68,2.8,6.22,4.59,6.53h0c.54.11,44.86,9.08,51.15,10.19,10.47,1.85,8.19,1.83,14.38-7.05,4.07-5.83,4.07-14.45,4.07-20.15s2.56-15.13,4.72-22.52S438.18,275.62,433.42,274.36Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M426.66,274.49c-5.6,2.08-9.07,7-10.77,14.53s-.19,13.76-1.78,24.63a64.19,64.19,0,0,1-5.6,17.05c-2.16,4.09-5.23,5.79-11,4.79s-26.15-4-31.48-6.47a8.27,8.27,0,0,1-4.9-7.82,29.28,29.28,0,0,0,10.12-1.86c4.55-1.92,4.21-4.1,4.07-5.29l-5.5-7.39h-1.7a8.67,8.67,0,0,0-4.93,2.34c-5.64,5.6-6.71,11.86-6.23,17.82.3,3.68,2.8,6.22,4.59,6.53h0c.54.11,44.86,9.08,51.15,10.19,10.47,1.85,8.19,1.83,14.38-7.05,4.07-5.83,4.07-14.45,4.07-20.15s2.56-15.13,4.72-22.52a37.43,37.43,0,0,0,1.37-7.78C433,279.1,428.84,273.67,426.66,274.49Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M433.42,274.36l-.93-.23h0a25.25,25.25,0,0,0-3.12-.48c-7.19,1.44-10.58,7.8-11.61,16.54s-.09,18.53-.73,24.62-4.43,15.89-4.18,22.36A11.36,11.36,0,0,0,415,344c7.79,1.37,6.35.67,12-7.47,4.07-5.83,4.07-14.45,4.07-20.15s2.56-15.13,4.72-22.52S438.18,275.62,433.42,274.36Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M401.79,306.74a150.84,150.84,0,0,0-3.21,16.69,61.1,61.1,0,0,1,0-16.69Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M423,284.35c-3.27,8.4-2,21.16-2.72,29.42s-4.46,19.82-3.89,24.19,2.76,7.42,6.1,5.47a14,14,0,0,0,4.17-5c1-1.93,1.69-4,2.17-5.19,2.31-5.48,2.31-12.19,2.31-16.91,0-5.7,2.56-15.13,4.72-22.52,1.62-5.53,2.12-13,.27-16.88C434.69,273.73,426.59,275.12,423,284.35Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M420.56,305.41c0,.39,0,.78,0,1.17l14-8.07c.12-.46.25-.92.38-1.38Z"
          style="fill:#263238"
        ></path>
        <path
          d="M436.42,277.63l-13.87,8c-.14.45-.27.91-.4,1.39l14.58-8.42C436.64,278.27,436.53,277.94,436.42,277.63Z"
          style="fill:#263238"
        ></path>
        <path
          d="M433,304.45l-12.58,7.26c0,.41-.05.81-.07,1.2l12.33-7.12C432.77,305.34,432.88,304.9,433,304.45Z"
          style="fill:#263238"
        ></path>
        <path
          d="M418,326.65l13.13-7.58c0-.39,0-.78,0-1.16l-12.86,7.43C418.18,325.78,418.09,326.22,418,326.65Z"
          style="fill:#263238"
        ></path>
        <path
          d="M420.68,299.14l0,1.17,15.87-9.16c.09-.43.17-.86.25-1.3Z"
          style="fill:#263238"
        ></path>
        <path
          d="M419.66,318.35c-.08.43-.15.86-.23,1.29l12-6.91c.05-.41.11-.83.17-1.25Z"
          style="fill:#263238"
        ></path>
        <path
          d="M437.3,284.49c0-.39,0-.78,0-1.16l-16.17,9.34c0,.41-.09.81-.13,1.23Z"
          style="fill:#263238"
        ></path>
        <path
          d="M429.06,332.69c.18-.48.34-1,.5-1.45l-13,7.52a6.41,6.41,0,0,0,.3,1Z"
          style="fill:#263238"
        ></path>
        <path
          d="M430.73,325.56c0-.42.1-.83.14-1.24l-14,8.12c-.08.44-.15.87-.21,1.28Z"
          style="fill:#263238"
        ></path>
        <path
          d="M360.9,318.65c7.78.74,14.1-2.09,14.4-4.6,0,0,1.88-10.48-12-11.6s-14.26,9.05-14.26,9.05C349,313.9,353.11,317.92,360.9,318.65Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M360.9,318.65c7.78.74,14.1-2.09,14.4-4.6,0,0,1.88-10.48-12-11.6s-14.26,9.05-14.26,9.05C349,313.9,353.11,317.92,360.9,318.65Z"
          style="fill:#fff;opacity:0.7000000000000001"
        ></path>
        <path
          d="M375.3,314.05a9.7,9.7,0,0,0-2.54-7.93c.53,1,2.75,6.1-5.38,8.44-9.06,2.59-17.37-3.1-17.83-5.34h0a8.7,8.7,0,0,0-.5,2.28c0,2.4,4.06,6.42,11.85,7.15S375,316.56,375.3,314.05Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M375.3,314.05a9.7,9.7,0,0,0-2.54-7.93c.53,1,2.75,6.1-5.38,8.44-9.06,2.59-17.37-3.1-17.83-5.34h0a8.7,8.7,0,0,0-.5,2.28c0,2.4,4.06,6.42,11.85,7.15S375,316.56,375.3,314.05Z"
          style="opacity:0.05"
        ></path>
        <path
          d="M345.66,218.05c-.2-2.17-1-6.68-4.79-8.46s-5.84,0-5.3,1.66,4.45,2.19,4.61,7.89C340.18,219.14,343.58,220.61,345.66,218.05Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M342.36,217.17a8.77,8.77,0,0,0-4-5.94c-1.21-.95-1.4-1.94,0-2.36-2.16-.19-3.23,1.12-2.82,2.38.55,1.69,4.45,2.19,4.61,7.89a6.05,6.05,0,0,0,2.45.41C342.58,218.84,342.49,218,342.36,217.17Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M337.52,222c2.45-5,10.23-5.64,16.28,0,9.08,8.47,10.57,16.86,6.74,21.32s-9.68,3.18-14.88-1.5C341.66,238.19,334,229.17,337.52,222Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M360.54,243.3a8.37,8.37,0,0,0,1.95-5.06c-.47,2.5-1.73,4.27-3.43,4.87-2.08.74-5.78,1.89-11.92-4.46s-9.36-16.9-5.18-20.27a7,7,0,0,0-4.44,3.6c-3.54,7.19,4.14,16.21,8.14,19.82C350.86,246.48,356.71,247.77,360.54,243.3Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M324.41,241.33c-15.39-8.19-23.77-12.28-31.35-8.35l-3.69-7.12c11.67-6.05,23.05,0,38.81,8.4Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M303.56,232.51a.52.52,0,0,1-.41-.82,5.63,5.63,0,0,0,.87-4.22,5.3,5.3,0,0,0-1.79-3.42.51.51,0,1,1,.59-.83,6.21,6.21,0,0,1,2.21,4.09,6.67,6.67,0,0,1-1.06,5A.51.51,0,0,1,303.56,232.51Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M312,235.55a.52.52,0,0,1-.37-.87,6.06,6.06,0,0,0,1.57-4.58,5,5,0,0,0-1.18-3.27.51.51,0,1,1,.65-.79,5.8,5.8,0,0,1,1.55,4,7.06,7.06,0,0,1-1.86,5.36A.51.51,0,0,1,312,235.55Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M293.06,233c7.58-3.93,16,.16,31.35,8.35l1.56-2.92a89.54,89.54,0,0,0-11-6c-10.83-4.9-14.95-5.45-23.44-2.28Z"
          style="opacity:0.15"
        ></path>
        <circle
          cx="283.82"
          cy="231.03"
          r="13"
          transform="translate(-66.81 126.2) rotate(-22.5)"
          style="fill:currentColor"
        ></circle>
        <circle
          cx="283.82"
          cy="231.03"
          r="13"
          transform="translate(-66.81 126.2) rotate(-22.5)"
          style="opacity:0.25"
        ></circle>
        <circle cx="270.95" cy="302.08" r="32.34" style="fill:currentColor"></circle>
        <circle
          cx="270.95"
          cy="302.08"
          r="32.34"
          style="opacity:0.35000000000000003"
        ></circle>
        <path
          d="M273,327.42c-8-.92-11.82-5.75-11.82-5.75s28.06.08,41.5-26a32.38,32.38,0,0,0-17.36-22.56l-37.79,6.72a32.34,32.34,0,1,0,51.15,39C292.22,326.42,281.12,328.37,273,327.42Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M302.42,284.8h0l-.17-.34c-.05-.09-.09-.18-.13-.26a154.46,154.46,0,0,1-8.81-24,139.68,139.68,0,0,1-4.64-29.78H214.13c0,27.92,5.36,55.35,17.57,76.58h0c2.08,4.09,6.48,7.47,13,9.45,16.09,4.87,39.07-.51,51.33-12C303.13,297.85,305.08,290.65,302.42,284.8Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M294.25,306.06c-5.22,3.84-17.06,8.61-25.92,8.53-10.14-.1-30.47-5.72-37.74-21.59S221,247.77,221,247.77s8.61,4.94,28.1,4.46l-2.55-21.78h-32.4c0,27.92,5.36,55.35,17.57,76.58h0c2.08,4.09,6.48,7.47,13,9.45C260.06,321.12,281.64,316.45,294.25,306.06Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M298.16,274.83c-.1-.27-.21-.54-.31-.82s-.12-.32-.19-.48l0-.11c-1.35-3.65-2.85-8.08-4.31-13.19a126.4,126.4,0,0,1-3.55-17.65l0-.08c.83,6.05-2.76,12.28-10.8,16.91-9.6,5.54-23.14,7.44-35.42,5.66,1.44,13.77,6.08,28.26,13.72,39.18,12,.88,26.09-3.57,34.07-11C297.86,287.07,300,280.43,298.16,274.83Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M296.26,274.1c-.1-.26-.19-.54-.29-.82l-.18-.48,0-.11c-1.28-3.68-2.7-8.14-4.05-13.27a112.71,112.71,0,0,1-2.38-11.59c-1.25,4.24-4.7,8.31-10.38,11.58-9.6,5.54-23.14,7.44-35.42,5.66,1.34,12.76,5.42,26.12,12.09,36.7,11.66.73,25.66-2.42,33.44-9.42C295.72,286.34,298,279.74,296.26,274.1Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M274.18,270.69l-3.76-1.7s-13.73,3.21-22,2.44A53.23,53.23,0,0,0,250,283.62a39.64,39.64,0,0,0,4.5,10.82l4.33,2.1Z"
          style="fill:#263238"
        ></path>
        <path
          d="M251.49,274.86c0,5.23,4.33,18.09,7.35,21.68,0,0,15.17-.71,21.7-6.69-.21-1.67-6.36-19.16-6.36-19.16C270.17,273.65,258.32,274.87,251.49,274.86Z"
          style="fill:#37474f"
        ></path>
        <circle cx="275.79" cy="287.53" r="1.51" style="fill:currentColor"></circle>
        <circle cx="271.16" cy="289.04" r="0.74" style="fill:currentColor"></circle>
        <path
          d="M275.07,279.12a.48.48,0,0,1-.2,0,.5.5,0,0,1-.46-.3.52.52,0,0,1,.27-.66,2.94,2.94,0,0,0,1.7-3.51l-3.53-12.42c.31-.12.63-.24.93-.37l3.56,12.52A3.93,3.93,0,0,1,275.07,279.12Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M269,281.05a.48.48,0,0,1-.2,0,.51.51,0,0,1-.46-.31.52.52,0,0,1,.27-.66,2.93,2.93,0,0,0,1.69-3.5l-3.55-12.52,1-.24,3.55,12.49A3.93,3.93,0,0,1,269,281.05Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M263,283a.43.43,0,0,1-.19,0,.5.5,0,0,1-.47-.3.52.52,0,0,1,.27-.66,2.93,2.93,0,0,0,1.69-3.51l-3.78-13.31,1-.13,3.73,13.17A3.92,3.92,0,0,1,263,283Z"
          style="fill:currentColor"
        ></path>
        <g style="opacity:0.30000000000000004">
          <path
            d="M275.07,279.12a.48.48,0,0,1-.2,0,.5.5,0,0,1-.46-.3.52.52,0,0,1,.27-.66,2.94,2.94,0,0,0,1.7-3.51l-3.53-12.42c.31-.12.63-.24.93-.37l3.56,12.52A3.93,3.93,0,0,1,275.07,279.12Z"
          ></path>
          <path
            d="M269,281.05a.48.48,0,0,1-.2,0,.51.51,0,0,1-.46-.31.52.52,0,0,1,.27-.66,2.93,2.93,0,0,0,1.69-3.5l-3.55-12.52,1-.24,3.55,12.49A3.93,3.93,0,0,1,269,281.05Z"
          ></path>
          <path
            d="M263,283a.43.43,0,0,1-.19,0,.5.5,0,0,1-.47-.3.52.52,0,0,1,.27-.66,2.93,2.93,0,0,0,1.69-3.51l-3.78-13.31,1-.13,3.73,13.17A3.92,3.92,0,0,1,263,283Z"
          ></path>
        </g>
        <path
          d="M279,259.41c-.77.45-1.58.86-2.4,1.26.88,4.47,2.5,11.42,4.14,15.19,0,0,8-2.42,10.65-7.68a124.51,124.51,0,0,1-4-16.19A24.77,24.77,0,0,1,279,259.41Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M286.28,258.28a.87.87,0,1,1-.87-.87A.87.87,0,0,1,286.28,258.28Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M287.51,262.23a.87.87,0,1,1-.86-.87A.86.86,0,0,1,287.51,262.23Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M289.25,266.25a.87.87,0,1,1-.87-.87A.87.87,0,0,1,289.25,266.25Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M282.12,272.73a.51.51,0,0,1-.47-.31,93.61,93.61,0,0,1-2.78-10.81.51.51,0,0,1,.41-.58.52.52,0,0,1,.58.42A94.68,94.68,0,0,0,282.58,272a.5.5,0,0,1-.27.65A.46.46,0,0,1,282.12,272.73Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M284.87,271a.51.51,0,0,1-.47-.31,95.71,95.71,0,0,1-2.78-10.81.5.5,0,0,1,1-.17,93.55,93.55,0,0,0,2.72,10.59.51.51,0,0,1-.27.66A.65.65,0,0,1,284.87,271Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M349.73,264.32s-6.49,10.29-21.47,15.77a65.3,65.3,0,0,1-26.33,3.68,157.39,157.39,0,0,1-8.61-23.54,125.25,125.25,0,0,1-3.55-17.65s11.56,1.5,23.94-6.25c14.12-8.83,17.35-18.3,17.35-18.3C339.82,230.38,346,245.94,349.73,264.32Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M349.73,264.32s-6.49,10.29-21.47,15.77a65.3,65.3,0,0,1-26.33,3.68,157.39,157.39,0,0,1-8.61-23.54,125.25,125.25,0,0,1-3.55-17.65s11.56,1.5,23.94-6.25c14.12-8.83,17.35-18.3,17.35-18.3C339.82,230.38,346,245.94,349.73,264.32Z"
          style="opacity:0.25"
        ></path>
        <path
          d="M349.73,264.32c-3.77-18.38-9.91-33.94-18.67-46.29a19.2,19.2,0,0,1-1.64,3.24c7.48,8.72,16.51,30.46,18,46A26.57,26.57,0,0,0,349.73,264.32Z"
          style="opacity:0.25"
        ></path>
        <circle cx="331.84" cy="249.76" r="2.57" style="fill:#37474f"></circle>
        <ellipse
          cx="251.4"
          cy="230.74"
          rx="37.28"
          ry="21.52"
          style="fill:currentColor"
        ></ellipse>
        <ellipse
          cx="251.4"
          cy="230.74"
          rx="37.28"
          ry="21.52"
          style="fill:#fff;opacity:0.5"
        ></ellipse>
        <path
          d="M225,215.52c-5.68,3.29-9.14,7.36-10.38,11.61,1,3.68,3.94,7.21,8.84,10,12.31,7.11,32.26,7.11,44.57,0s12.3-18.5.2-25.61C254.28,207.45,236.74,208.77,225,215.52Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M300.33,273a7.57,7.57,0,0,0-4.89-1.19,9.36,9.36,0,0,0-5.39,2.37,5.44,5.44,0,0,0-.48.48,9.2,9.2,0,0,0-4.94,3.26,7,7,0,0,0-1.34,2.86,5.44,5.44,0,0,0-1.2,2.71,6.11,6.11,0,0,0,1.27,4.38,9.33,9.33,0,0,0,6.65,3.62c.51-.5,1-1,1.45-1.51a8.12,8.12,0,0,1-5.34-1.55,5.67,5.67,0,0,1-2.62-4.76l0,.1a6.89,6.89,0,0,0,4,3.82,5,5,0,0,0,5.2-.71,4.23,4.23,0,0,0,1-1.38,7.4,7.4,0,0,0,.94,0,4.71,4.71,0,0,0,4.32-2.25,5.73,5.73,0,0,0,.13-4.79,6,6,0,0,0-2.51-2.93,7.22,7.22,0,0,0-1.18-.59,9.39,9.39,0,0,0-3.64-.64,8.26,8.26,0,0,1,4-1.54,8.57,8.57,0,0,1,2.32.05,7.15,7.15,0,0,1,2.07.66C300.57,273.68,300.6,273.16,300.33,273Zm-14.81,5.9a7.77,7.77,0,0,1,2.9-2.5,7.22,7.22,0,0,0-.6,1.86,8.18,8.18,0,0,0-2.41.82A1.74,1.74,0,0,1,285.52,278.87Zm3.41,7.59a5.68,5.68,0,0,1-3.66-2.45,4.22,4.22,0,0,1-.65-2.77,5.86,5.86,0,0,1,3.1-1.69,5.68,5.68,0,0,0,1.8,4.12,7.22,7.22,0,0,0,2.72,1.49A3.08,3.08,0,0,1,288.93,286.46Zm2-3.47a4.6,4.6,0,0,1-1.74-3.51,3.5,3.5,0,0,1,2.52,1.36,4.56,4.56,0,0,1,1,3A6,6,0,0,1,290.89,283Zm3.33-7.09a5.66,5.66,0,0,1,2.58,1.52,4,4,0,0,1,.76,1.06,5,5,0,0,1,.42,3.64,2.94,2.94,0,0,1-2.62,2,5.29,5.29,0,0,1-1.26,0,5.39,5.39,0,0,0-.2-2.31,5.15,5.15,0,0,0-3.58-3.57,6.4,6.4,0,0,0-1.07-.14,6,6,0,0,1,1.08-2.43A8,8,0,0,1,294.22,275.9Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M300.33,273a7.57,7.57,0,0,0-4.89-1.19,9.36,9.36,0,0,0-5.39,2.37,5.44,5.44,0,0,0-.48.48,9.2,9.2,0,0,0-4.94,3.26,7,7,0,0,0-1.34,2.86,5.44,5.44,0,0,0-1.2,2.71,6.11,6.11,0,0,0,1.27,4.38,9.33,9.33,0,0,0,6.65,3.62c.51-.5,1-1,1.45-1.51a8.12,8.12,0,0,1-5.34-1.55,5.67,5.67,0,0,1-2.62-4.76l0,.1a6.89,6.89,0,0,0,4,3.82,5,5,0,0,0,5.2-.71,4.23,4.23,0,0,0,1-1.38,7.4,7.4,0,0,0,.94,0,4.71,4.71,0,0,0,4.32-2.25,5.73,5.73,0,0,0,.13-4.79,6,6,0,0,0-2.51-2.93,7.22,7.22,0,0,0-1.18-.59,9.39,9.39,0,0,0-3.64-.64,8.26,8.26,0,0,1,4-1.54,8.57,8.57,0,0,1,2.32.05,7.15,7.15,0,0,1,2.07.66C300.57,273.68,300.6,273.16,300.33,273Zm-14.81,5.9a7.77,7.77,0,0,1,2.9-2.5,7.22,7.22,0,0,0-.6,1.86,8.18,8.18,0,0,0-2.41.82A1.74,1.74,0,0,1,285.52,278.87Zm3.41,7.59a5.68,5.68,0,0,1-3.66-2.45,4.22,4.22,0,0,1-.65-2.77,5.86,5.86,0,0,1,3.1-1.69,5.68,5.68,0,0,0,1.8,4.12,7.22,7.22,0,0,0,2.72,1.49A3.08,3.08,0,0,1,288.93,286.46Zm2-3.47a4.6,4.6,0,0,1-1.74-3.51,3.5,3.5,0,0,1,2.52,1.36,4.56,4.56,0,0,1,1,3A6,6,0,0,1,290.89,283Zm3.33-7.09a5.66,5.66,0,0,1,2.58,1.52,4,4,0,0,1,.76,1.06,5,5,0,0,1,.42,3.64,2.94,2.94,0,0,1-2.62,2,5.29,5.29,0,0,1-1.26,0,5.39,5.39,0,0,0-.2-2.31,5.15,5.15,0,0,0-3.58-3.57,6.4,6.4,0,0,0-1.07-.14,6,6,0,0,1,1.08-2.43A8,8,0,0,1,294.22,275.9Z"
          style="fill:#fff;opacity:0.5"
        ></path>
        <path
          d="M282.12,283.33a4.3,4.3,0,0,0,1.64,2.05,4.31,4.31,0,0,1-.26-1.74l0,.1a7.68,7.68,0,0,1-.24-3A5.45,5.45,0,0,0,282.12,283.33Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M285,280.88a6.22,6.22,0,0,1,.39-1.83,4.67,4.67,0,0,0-.79,2.19A4.64,4.64,0,0,1,285,280.88Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M287.19,277.15a7.28,7.28,0,0,1,1.23-.78,6.71,6.71,0,0,1,1.15-1.74,9.22,9.22,0,0,0-1,.34A4.93,4.93,0,0,0,287.19,277.15Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M287.82,278.23a5.59,5.59,0,0,0-.56.12,9,9,0,0,0-.23,1.39c.23-.07.46-.14.69-.19A4.38,4.38,0,0,1,287.82,278.23Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M293.72,285.47a14.8,14.8,0,0,1-1.48-.31,2.89,2.89,0,0,1-.58.67,3.66,3.66,0,0,0,1.44.57A4.86,4.86,0,0,0,293.72,285.47Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M291.78,274.32a11.5,11.5,0,0,0-1.45,1.4c.18,0,.36-.05.53-.08A9.72,9.72,0,0,1,291.78,274.32Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M289.15,279.48l.3.06a4.44,4.44,0,0,1,0-1.37l-.2,0A6.46,6.46,0,0,0,289.15,279.48Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M294.15,283.61a4.56,4.56,0,0,1-1.52,0,2.44,2.44,0,0,1,0,.27,6.09,6.09,0,0,0,1.46.29A5.07,5.07,0,0,0,294.15,283.61Z"
          style="opacity:0.15"
        ></path>
        <g id="freepik--Arm--inject-8">
          <path
            d="M231.67,268.64c0,2.8-.94,4.8-2.47,5.81A10.59,10.59,0,0,1,228,275a13,13,0,0,1-17.73-12.12,12.94,12.94,0,0,1,5.12-10.33.15.15,0,0,1,.1-.09c.16-.11.3-.22.46-.32l0,0c1.65-1,3.94-.82,6.48.64C227.56,255.67,231.67,262.78,231.67,268.64Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M231.67,268.64c0,2.8-.94,4.8-2.47,5.81A10.59,10.59,0,0,1,228,275a13,13,0,0,1-17.73-12.12,12.94,12.94,0,0,1,5.12-10.33.15.15,0,0,1,.1-.09c.16-.11.3-.22.46-.32l0,0c1.65-1,3.94-.82,6.48.64C227.56,255.67,231.67,262.78,231.67,268.64Z"
            style="opacity:0.25"
          ></path>
          <path
            d="M231.67,268.64c0-4.52-2.45-9.78-5.9-13.3,2.57,3.22,5,11.43,1.86,15.62s-14.72,1.81-17-5.07A13,13,0,0,0,228,275a10.59,10.59,0,0,0,1.19-.54C230.73,273.44,231.67,271.44,231.67,268.64Z"
            style="opacity:0.1"
          ></path>
          <path
            d="M216.48,348.39a13.35,13.35,0,0,0-.13,5.79c.16.8,1,1.46,1.37,1.07l7.9-8.1,6.34,1.57,1.81,6.29-8.27,8.49c-.31.32-.27.65.05.74l.59.16a13.21,13.21,0,0,0,11.38-23.17l10.39-43.31a13.23,13.23,0,0,0,10.35-15.54c-.17-.81-1-1.47-1.38-1.08l-7.9,8.1-6.34-1.57-1.81-6.29,8.27-8.5c.3-.3.26-.65,0-.74l-.59-.16a13.21,13.21,0,0,0-11.38,23.18l-10.39,43.31A13.2,13.2,0,0,0,216.48,348.39Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M216.48,348.39a13.35,13.35,0,0,0-.13,5.79c.16.8,1,1.46,1.37,1.07l7.9-8.1,6.34,1.57,1.81,6.29-8.27,8.49c-.31.32-.27.65.05.74l.59.16a13.21,13.21,0,0,0,11.38-23.17l10.39-43.31a13.23,13.23,0,0,0,10.35-15.54c-.17-.81-1-1.47-1.38-1.08l-7.9,8.1-6.34-1.57-1.81-6.29,8.27-8.5c.3-.3.26-.65,0-.74l-.59-.16a13.21,13.21,0,0,0-11.38,23.18l-10.39,43.31A13.2,13.2,0,0,0,216.48,348.39Z"
            style="fill:#fff;opacity:0.8"
          ></path>
          <path
            d="M243.12,315.63a6.45,6.45,0,0,1-6.45.71,7,7,0,0,1-3.84-3.31l-5.44,22.68a12.83,12.83,0,0,0,11.22,1l4.94-20.61C243.41,315.94,243.27,315.78,243.12,315.63Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M243.12,315.63a6.45,6.45,0,0,1-6.45.71,7,7,0,0,1-3.84-3.31l-5.44,22.68a12.83,12.83,0,0,0,11.22,1l4.94-20.61C243.41,315.94,243.27,315.78,243.12,315.63Z"
            style="opacity:0.05"
          ></path>
          <path
            d="M223.26,319.09c-8.44.6-20.64-3.93-26.18-9.25-2.93-2.83-3.57-6.79-2-12.49,3.05-11,16.24-26.21,22.5-29.16h0a1.93,1.93,0,0,0,.91-1.86,6.25,6.25,0,0,0-2.84-4.91,2,2,0,0,0-1.95-.22h0c-8.72,4.11-23.31,21.47-26.87,34.29-2.39,8.6-1,15.47,4.15,20.41,6.65,6.39,20.18,11.29,30.64,11.29.75,0,1.5,0,2.22-.08Z"
            style="fill:#455a64"
          ></path>
          <path
            d="M210.62,274.25h0a.6.6,0,0,1-.6-.61,4.73,4.73,0,0,0-1.92-3.46,4.68,4.68,0,0,0-3.62-1.25.6.6,0,0,1-.71-.47.61.61,0,0,1,.47-.71,6.59,6.59,0,0,1,7,5.9A.6.6,0,0,1,210.62,274.25Z"
            style="fill:#37474f"
          ></path>
          <path
            d="M205.64,279.75a.6.6,0,0,1-.57-.4c-.82-2.27-4.35-4.32-6.52-3.79a.6.6,0,1,1-.29-1.17c2.76-.68,6.92,1.71,7.94,4.55a.59.59,0,0,1-.36.77A.51.51,0,0,1,205.64,279.75Z"
            style="fill:#37474f"
          ></path>
          <path
            d="M200.19,287.25a.6.6,0,0,1-.56-.37,6.44,6.44,0,0,0-6.64-3.57.6.6,0,0,1-.72-.46.61.61,0,0,1,.45-.72,7.57,7.57,0,0,1,8,4.3.6.6,0,0,1-.34.78A.57.57,0,0,1,200.19,287.25Z"
            style="fill:#37474f"
          ></path>
          <path
            d="M196.24,294.7a.6.6,0,0,1-.57-.4c-.26-.69-1.65-1.42-3.24-1.68s-3.49-.12-4.42,1a.6.6,0,0,1-.85.09.61.61,0,0,1-.08-.85c1.06-1.31,3.13-1.85,5.55-1.45,1.6.26,3.66,1.07,4.17,2.46a.59.59,0,0,1-.35.77A.52.52,0,0,1,196.24,294.7Z"
            style="fill:#37474f"
          ></path>
          <path
            d="M185.73,304.67a.69.69,0,0,1-.2,0,.61.61,0,0,1-.37-.77c.77-2.18,3.5-3.26,5.87-3.35,1.91-.08,3.33.48,3.78,1.5a.6.6,0,0,1-.3.8.62.62,0,0,1-.8-.31c-.23-.52-1.3-.84-2.63-.79-2,.08-4.23,1-4.78,2.55A.6.6,0,0,1,185.73,304.67Z"
            style="fill:#37474f"
          ></path>
          <path
            d="M188.91,314.1h0a.6.6,0,0,1-.55-.65c.24-2.65,2.89-4.31,5.08-4.93.65-.18,2.83-.69,3.62.37a.6.6,0,0,1-1,.72c-.11-.15-.95-.32-2.38.09-1.8.52-4,1.84-4.15,3.86A.6.6,0,0,1,188.91,314.1Z"
            style="fill:#37474f"
          ></path>
          <path
            d="M197.37,321.09a.61.61,0,0,1-.54-.33,4.8,4.8,0,0,1,.91-5.42c1.48-1.71,3.66-2.58,5.2-2.07a.61.61,0,0,1-.39,1.15c-1.05-.36-2.77.39-3.9,1.71a3.7,3.7,0,0,0-.75,4.08.6.6,0,0,1-.26.81A.54.54,0,0,1,197.37,321.09Z"
            style="fill:#37474f"
          ></path>
          <path
            d="M205.51,324.75a.61.61,0,0,1-.55-.37,6.63,6.63,0,0,1,.77-5.88,4.58,4.58,0,0,1,3.28-2.43.6.6,0,0,1,.63.57.59.59,0,0,1-.57.63,3.63,3.63,0,0,0-2.32,1.87,5.41,5.41,0,0,0-.68,4.77.6.6,0,0,1-.32.79A.69.69,0,0,1,205.51,324.75Z"
            style="fill:#37474f"
          ></path>
          <path
            d="M213.75,327a.61.61,0,0,1-.54-.34,7.32,7.32,0,0,1,.08-5.75,4.86,4.86,0,0,1,2.6-2.86.6.6,0,1,1,.4,1.13,3.8,3.8,0,0,0-1.89,2.19,6.18,6.18,0,0,0-.1,4.77.6.6,0,0,1-.29.8A.58.58,0,0,1,213.75,327Z"
            style="fill:#37474f"
          ></path>
          <path
            d="M188.16,297.57C191,284.79,201.59,274,207.1,268.83c3.54-3.3,7.53-5.41,10.24-5.82a5.42,5.42,0,0,0-1.68-1.6,2,2,0,0,0-1.95-.22h0c-8.72,4.11-23.31,21.47-26.87,34.29-2.39,8.6-1,15.47,4.15,20.41C191,315.88,185.33,310.36,188.16,297.57Z"
            style="opacity:0.15"
          ></path>
          <path
            d="M223.26,319.09c-8.44.6-20.64-3.93-26.18-9.25a9,9,0,0,1-2.66-5h-.16c.65,4.08,1.17,8.9,12.84,13.57a38,38,0,0,0,16.31,2.7Z"
            style="opacity:0.15"
          ></path>
          <path
            d="M228.6,312.15c-.35-3.61.13-7.73,1.69-10.25s4.09-4.25,5.28-2.18c.8,1.38-.89,3.49-1.68,6.08a11.69,11.69,0,0,0-.17,4.78Z"
            style="fill:#455a64"
          ></path>
          <path
            d="M233.89,305.8c.79-2.59,2.48-4.7,1.68-6.08a1.7,1.7,0,0,0-1-.84h0c.68.93-.92,2.67-2.05,4.9a14.09,14.09,0,0,0-1,7.47l2.2-.67A11.69,11.69,0,0,1,233.89,305.8Z"
            style="opacity:0.15"
          ></path>
          <path
            d="M247.6,325.38a12.85,12.85,0,1,1-14-15.6c.13,1.67,2,3.68,4.11,4.44a6.45,6.45,0,0,0,6.45-.72A12.84,12.84,0,0,1,247.6,325.38Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M247.6,325.38a12.85,12.85,0,1,1-14-15.6c.13,1.67,2,3.68,4.11,4.44a6.45,6.45,0,0,0,6.45-.72A12.84,12.84,0,0,1,247.6,325.38Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M247.6,325.38a11.38,11.38,0,0,0,.22-1.3c-1.77,3.57-6.06,8.54-14.57,7.08-8.74-1.5-10.55-12-9.41-14.83a12.44,12.44,0,0,0-1.31,3.43,12.85,12.85,0,1,0,25.07,5.62Z"
            style="opacity:0.1"
          ></path>
          <path
            d="M247.67,320c4.38-1.77,5.82-5.78,5.14-9.84s-6.49-5.65-6.49-5.65l-1.12,4.68s2.86,1.07,2.72,3.07a4.22,4.22,0,0,1-2.23,3A3.51,3.51,0,0,0,247.67,320Z"
            style="fill:#455a64"
          ></path>
          <path
            d="M246.32,304.55l-1.12,4.68s2.86,1.07,2.72,3.07a4.22,4.22,0,0,1-2.23,3,3.5,3.5,0,0,0,.88,4h0c-.46-1.57.54-2.33,2.1-4s.94-4.49-.06-6.26a5.09,5.09,0,0,1-.3-3.77A12.45,12.45,0,0,0,246.32,304.55Z"
            style="opacity:0.15"
          ></path>
          <path
            d="M247.09,327.09c3.41,1,7.53-.39,8.08-3.17s-.37-5.08-3.59-7a8.45,8.45,0,0,1-3.91,3.08l.21,1.5A4.83,4.83,0,0,0,247.09,327.09Z"
            style="fill:#455a64"
          ></path>
          <path
            d="M251.58,317a8.45,8.45,0,0,1-3.91,3.08l.21,1.5h4.17s-1.08-.35-.77-1.06,2.06-.44,2.91.66a3.76,3.76,0,0,1,.22,4.31,3.35,3.35,0,0,0,.76-1.53C255.73,321.14,254.8,318.84,251.58,317Z"
            style="opacity:0.15"
          ></path>
          <path
            d="M242.46,333.08a14,14,0,0,0,4.57,2.7c1.31.49,3.6.52,4.83-.79s.38-2.76-.54-3.41a30.56,30.56,0,0,0-5-2.8S242.76,328.78,242.46,333.08Z"
            style="fill:#455a64"
          ></path>
          <path
            d="M251.32,331.58a30.56,30.56,0,0,0-5-2.8,4,4,0,0,0-2.94,1.54c.75-.75,3.26.64,5.31,1.88s3.66,1.94,2.82,3.08a3.17,3.17,0,0,0,.35-.29C253.17,333.57,252.24,332.23,251.32,331.58Z"
            style="opacity:0.15"
          ></path>
        </g>
        <path
          d="M345.49,230a2.78,2.78,0,0,0,1.48-3.45c-.83-2.41-3.74-3.12-3.74-3.12s-5.39-1.49-9.5,1-4.17,7.7-4.05,9.37a2.35,2.35,0,0,0,4.55.5c.95-2.05.94-4.89,4.37-5.89C341.43,227.57,343.81,230.74,345.49,230Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M343.84,227.82c-2.86-1.17-5.92-2.7-8.35,0s-1.93,5.34-2.94,6.45-2.22.92-2.76,0a2.35,2.35,0,0,0,4.44,0c.95-2.05.94-4.89,4.37-5.89,2.83-.82,5.21,2.35,6.89,1.61a2.78,2.78,0,0,0,1.48-3.45C347,227.76,346.08,228.73,343.84,227.82Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M351.06,241.68a2.11,2.11,0,0,1,2.59-.22,2.77,2.77,0,0,1,1.66,2.31s.15,3.54-3.69,7.17a11.21,11.21,0,0,1-9,2.79c-2.52-.68-2.95-3.52,0-4.16,2.21-.48,4.16-.43,6.53-3.09C350.6,244.84,350.6,242.67,351.06,241.68Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M350.11,249.76c-3.89,3-6.28,2.65-7.73,2.29s-1.43-1.76-.82-2.07c-1.7,1-1.09,3.17,1.07,3.75a11.21,11.21,0,0,0,9-2.79c3.84-3.63,3.69-7.17,3.69-7.17a2.77,2.77,0,0,0-1.66-2.31C354.38,242.41,354.28,246.61,350.11,249.76Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M347.28,231.6a4,4,0,0,1,3.64,2c1.92,2.91-.34,4.13-.34,4.13-1.49.18-3.37-.76-6.42.45-3.79,1.5-4.21,4.38-5.1,6.65-1,2.52-4.85,1.15-4.22-2.78s2.25-6.47,4.82-8.13A14.52,14.52,0,0,1,347.28,231.6Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M348.53,235.44c-2.67-.14-6.64.27-8.44,3.14-1.21,1.94-1.51,4.65-2.57,5.45a1.68,1.68,0,0,1-2.74-1.05c.06,3.21,3.38,4.17,4.28,1.86s1.31-5.15,5.1-6.65c3-1.21,4.93-.27,6.42-.45,0,0,2.06-1.12.56-3.77C351.56,235,351.19,235.59,348.53,235.44Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M280.45,322.5h0a6,6,0,1,0-9.08,7.86h0l38.34,44.37,9.1-7.86Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M277.26,337.77a.63.63,0,0,1-.46-.2,6.61,6.61,0,1,1,10-8.64.6.6,0,1,1-.91.78,5.41,5.41,0,1,0-8.18,7.07.6.6,0,0,1-.06.85A.57.57,0,0,1,277.26,337.77Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M283.77,345.31a.6.6,0,0,1-.46-.21,6.61,6.61,0,1,1,10-8.64.6.6,0,1,1-.91.79,5.4,5.4,0,1,0-8.18,7.06.6.6,0,0,1-.06.85A.58.58,0,0,1,283.77,345.31Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M290.28,352.84a.63.63,0,0,1-.46-.2,6.61,6.61,0,1,1,10-8.64.6.6,0,0,1-.91.78,5.41,5.41,0,1,0-8.18,7.07.6.6,0,0,1-.06.85A.57.57,0,0,1,290.28,352.84Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M296.79,360.38a.6.6,0,0,1-.46-.21,6.61,6.61,0,1,1,10-8.64.6.6,0,1,1-.91.79,5.4,5.4,0,1,0-8.18,7.06.6.6,0,0,1-.06.85A.58.58,0,0,1,296.79,360.38Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M303.3,367.91a.6.6,0,0,1-.46-.21,6.61,6.61,0,1,1,10-8.64.6.6,0,1,1-.91.79,5.41,5.41,0,1,0-8.18,7.07.6.6,0,0,1-.06.85A.62.62,0,0,1,303.3,367.91Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M275.9,320.42a6,6,0,0,0-4.54,9.94h0l38.34,44.37,3.78-3.27c-11.71-13.11-30.71-34.39-35.83-40.22C270.09,322.64,273.17,321.07,275.9,320.42Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M380.27,372.91s-11.22-9.79-20.21-3.39a67.54,67.54,0,0,0-14.4,14l-24.22-21.41h0c-1.49-1.29-4.09-1.32-6.66.16-4.5,2.6-8.14,8.91-8.14,14.1a7,7,0,0,0,1.12,4.1h0s26.63,37.23,31.16,43,10.28,8.8,18,5.28,10-13.6,10.93-20.41,9.65-16.36,12-22.47C382.3,379.19,381.71,375.16,380.27,372.91Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M371.52,370.22c-4.29.36-14.58,5.68-17.59,17a93.07,93.07,0,0,1-4.2,12.72,29.43,29.43,0,0,1-5.66,8.42c-3.55,3.77-7.8,1.2-11.48-1.85S318.51,389.79,315,383.9c-2.57-4.26-3.38-7.49-2.68-10.59.24.32.49.63.74.91,3.46,3.84,10.07,7,15.11,3.15,2.46-1.87,2.66-4.82,1.85-7.74l-8.54-7.55c-1.49-1.29-4.09-1.32-6.66.16-.27.16-.53.33-.79.5l-.27.2-.49.37-.32.27-.42.37-.34.32-.36.36-.35.37-.32.37c-.12.13-.24.27-.35.41l-.28.36c-.12.15-.24.31-.35.47s-.15.21-.22.31a17.34,17.34,0,0,0-3.28,9.42,7,7,0,0,0,1.12,4.1h0s26.63,37.23,31.16,43,10.28,8.8,18,5.28,10-13.6,10.93-20.41c.68-5,5.62-11.55,9.09-17.08Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M379.77,385.84c2.53-6.65,1.94-10.68.5-12.93a27.13,27.13,0,0,0-4.74-3.15c-6.19-.79-13.79,6.47-16.6,11.12s-4.32,10.27-6.35,17.77c-1.8,6.65-6.31,10.36-8.87,16.61-1.86,4.54-2.28,8-1,12,3.86,3,8.49,4,14.19,1.43,7.67-3.51,10-13.6,10.93-20.41S377.45,392,379.77,385.84Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M360.72,383.49c-4,7.13-4.8,17-8.18,22.77s-8.5,12.16-7.91,17.7c.38,3.62,1.65,5.52,4,6.09a12.23,12.23,0,0,0,3.87,0l.52-.1a16.73,16.73,0,0,0,3.83-1.25c7.67-3.51,10-13.6,10.93-20.41s9.65-16.36,12-22.47c2.53-6.65,1.94-10.68.5-12.93C375.38,369.75,366,374.09,360.72,383.49Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M344.07,382.09c-5.35,5.13-6.79,13-6.79,13a118.86,118.86,0,0,1,8.38-11.59Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M354.54,401.67c-.15.43-.3.84-.45,1.25l16.73-2.13c.24-.42.49-.85.75-1.28Z"
          style="fill:#263238"
        ></path>
        <path
          d="M351.67,407.7q-.39.65-.81,1.29l17.27-2.19c.11-.41.24-.82.39-1.24Z"
          style="fill:#263238"
        ></path>
        <path
          d="M345.21,426.7,362,424.56c.34-.42.65-.86,1-1.31l-18.09,2.3A11.23,11.23,0,0,0,345.21,426.7Z"
          style="fill:#263238"
        ></path>
        <path
          d="M345.09,419.86q-.18.61-.3,1.23l20.6-2.62c.16-.41.3-.83.44-1.25Z"
          style="fill:#263238"
        ></path>
        <path
          d="M347.86,413.85c-.25.42-.49.85-.72,1.28l19.93-2.54c.09-.41.17-.81.24-1.22Z"
          style="fill:#263238"
        ></path>
        <path
          d="M365.08,377.69c-.42.42-.83.87-1.24,1.34l17.62-2.24a10.36,10.36,0,0,0-.15-1.17Z"
          style="fill:#263238"
        ></path>
        <path
          d="M360.49,383.93c-.23.42-.44.84-.65,1.27l21-2.67c.11-.42.21-.83.29-1.22Z"
          style="fill:#263238"
        ></path>
        <path
          d="M358,389.91c-.13.41-.27.82-.39,1.24l20.9-2.66c.24-.44.46-.86.66-1.27Z"
          style="fill:#263238"
        ></path>
        <path
          d="M356.28,395.79c-.12.41-.23.83-.34,1.23l18.74-2.38.85-1.3Z"
          style="fill:#263238"
        ></path>
        <path
          d="M313.08,357c-5.55,3.83-2.81,11.88.65,15.73s10.07,7,15.11,3.14c4.63-3.52,1.28-10.89-1.84-14.65C323.74,357.24,317.46,353.94,313.08,357Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M313.08,357c-5.55,3.83-2.81,11.88.65,15.73s10.07,7,15.11,3.14c4.63-3.52,1.28-10.89-1.84-14.65C323.74,357.24,317.46,353.94,313.08,357Z"
          style="fill:#fff;opacity:0.7000000000000001"
        ></path>
        <path
          d="M331.11,371c-.39,2.73-4.57,6.27-11.62.82s-7.21-13.61-4.89-15.61a7,7,0,0,0-1.52.77c-5.55,3.83-2.81,11.88.65,15.73s10.06,7,15.11,3.14A5.8,5.8,0,0,0,331.11,371Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M331.11,371c-.39,2.73-4.57,6.27-11.62.82s-7.21-13.61-4.89-15.61a7,7,0,0,0-1.52.77c-5.55,3.83-2.81,11.88.65,15.73s10.06,7,15.11,3.14A5.8,5.8,0,0,0,331.11,371Z"
          style="opacity:0.05"
        ></path>
        <path
          d="M258.89,228.39V203.87h-15v24.52h0a3.71,3.71,0,0,0,2.19,3.06,11.71,11.71,0,0,0,10.6,0A3.71,3.71,0,0,0,258.89,228.39Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M251.4,228.52a11.38,11.38,0,0,1-5.55-1.32,4.22,4.22,0,0,1-2.45-3.51.51.51,0,0,1,.51-.51.51.51,0,0,1,.51.51,3.25,3.25,0,0,0,1.94,2.62,11.12,11.12,0,0,0,10.08,0,3.25,3.25,0,0,0,1.94-2.62.52.52,0,0,1,1,0A4.22,4.22,0,0,1,257,227.2,11.45,11.45,0,0,1,251.4,228.52Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M251.4,223.82a11.38,11.38,0,0,1-5.55-1.32,4.22,4.22,0,0,1-2.45-3.5.51.51,0,0,1,.51-.52.51.51,0,0,1,.51.52,3.25,3.25,0,0,0,1.94,2.61,11.12,11.12,0,0,0,10.08,0,3.25,3.25,0,0,0,1.94-2.62.52.52,0,0,1,1,0A4.22,4.22,0,0,1,257,222.5,11.45,11.45,0,0,1,251.4,223.82Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M251.4,219.12a11.38,11.38,0,0,1-5.55-1.32,4.22,4.22,0,0,1-2.45-3.5.51.51,0,0,1,.51-.52.51.51,0,0,1,.51.52,3.25,3.25,0,0,0,1.94,2.61,11.12,11.12,0,0,0,10.08,0,3.25,3.25,0,0,0,1.94-2.62.52.52,0,0,1,1,0A4.22,4.22,0,0,1,257,217.8,11.45,11.45,0,0,1,251.4,219.12Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M247.14,203.87h-3.23v24.52a3.71,3.71,0,0,0,2.19,3.06,7.09,7.09,0,0,0,1,.49V215.89A45.11,45.11,0,0,0,258.89,217V203.87Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M220.37,168.12c0-18.57,15-30.57,33.62-30.57s33.61,12,33.61,30.57v20.32S280.91,199,254,199s-33.62-10-33.62-10Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M259.33,144.05a3.46,3.46,0,0,0-1.88,1.9,2.63,2.63,0,0,0,.78,2.44,7.25,7.25,0,0,0,2.28,1.38,77.24,77.24,0,0,0,13.72,4.57c1.18.27,3.49,1.09,4.36,0,1-1.32.4-4-.95-5.72a13.64,13.64,0,0,0-8-5.42A14,14,0,0,0,259.33,144.05Z"
          style="fill:#fff;opacity:0.2"
        ></path>
        <path
          d="M287.6,175.64s-1.88,10.18-15.2,15.15-27.78,3.39-36.62,1.16c0,0,18.92,9.23,38.45.37C291.32,184.57,287.6,175.64,287.6,175.64Z"
          style="opacity:0.2"
        ></path>
        <path
          d="M265.61,139.24A40,40,0,0,0,254,137.55c-18.57,0-33.62,12-33.62,30.57v20.81s2.75,4.1,11.76,7.06c-1.41-13.51-3.22-30.75,2.16-41.3C240.44,142.64,257.53,136.89,265.61,139.24Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M253,181.92h0a5,5,0,0,1-5-5v-2.31a5,5,0,0,1,5-5h0a5,5,0,0,1,5,5v2.31A5,5,0,0,1,253,181.92Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M278,173.93h0c-2.37.88-3.62-.22-4.07-1.45a13.11,13.11,0,0,1-.31-5,6.48,6.48,0,0,1,3.57-5.38h0a2.84,2.84,0,0,1,4.22,1.64,14.47,14.47,0,0,1,.25,4.49C281.54,170.15,280.35,172.91,278,173.93Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M291.08,174.34a5.77,5.77,0,0,0-3.48-1.72v3c0,5-3.28,9.94-9.84,13.73-11.41,6.58-29,7.44-42,2.58v-1a13.6,13.6,0,0,0-6.16-10.66c-3.4-2-6.15-.38-6.15,3.55a13,13,0,0,1-3.1-8.15c-1.15,0-2.67.62-2.67,5,0,5.45-.27,11.57,4.9,18.32,8.35,10.91,30.31,20.53,49.21,13,20.15-8,20.26-23.13,20.26-29.27C292.07,178.15,292.43,175.89,291.08,174.34Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M291.08,174.34a5.77,5.77,0,0,0-3.48-1.72v3c0,5-3.28,9.94-9.84,13.73-11.41,6.58-29,7.44-42,2.58v-1a13.6,13.6,0,0,0-6.16-10.66c-3.4-2-6.15-.38-6.15,3.55a13,13,0,0,1-3.1-8.15c-1.15,0-2.67.62-2.67,5,0,5.45-.27,11.57,4.9,18.32,8.35,10.91,30.31,20.53,49.21,13,20.15-8,20.26-23.13,20.26-29.27C292.07,178.15,292.43,175.89,291.08,174.34Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M260.19,213.09c-22.25-.34-33-11.9-35.78-17.1s-2.56-10.53-.94-9.84v-2.36a13,13,0,0,1-3.1-8.15c-1.15,0-2.67.62-2.67,5,0,5.45-.27,11.57,4.9,18.32,8.35,10.91,30.31,20.53,49.21,13a36.14,36.14,0,0,0,12.47-7.88C279.16,207.94,270.91,213.25,260.19,213.09Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M230.59,180.89c2.11,1.64,3.7,5.12,3.7,11.93l1.49-.87v-1A13.81,13.81,0,0,0,230.59,180.89Z"
          style="opacity:0.2"
        ></path>
        <path
          d="M287.6,175.64c0,5-3.28,9.94-9.84,13.73-11.41,6.58-29,7.44-42,2.58l-1.49.87s18.2,10,38.74,1.88c19.17-7.55,18.76-17.76,18.05-20.36a5.77,5.77,0,0,0-3.48-1.72Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M287.6,175.64c0,5-3.28,9.94-9.84,13.73-11.41,6.58-29,7.44-42,2.58l-1.49.87s18.2,10,38.74,1.88c19.17-7.55,18.76-17.76,18.05-20.36a5.77,5.77,0,0,0-3.48-1.72Z"
          style="fill:#fff;opacity:0.6000000000000001"
        ></path>
        <path
          d="M228.74,183.62a5.62,5.62,0,0,1,2.54,4.4c0,1.62-1.14,2.28-2.54,1.47a5.6,5.6,0,0,1-2.54-4.4C226.2,183.47,227.33,182.81,228.74,183.62Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M228.74,183.62a2.19,2.19,0,0,0-1.25-.33,2.1,2.1,0,0,0-.4,1.34,5.62,5.62,0,0,0,2.54,4.4,2.19,2.19,0,0,0,1.25.33,2.1,2.1,0,0,0,.4-1.34A5.62,5.62,0,0,0,228.74,183.62Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M223.47,183.79a13,13,0,0,1-3.1-8.15,2.11,2.11,0,0,0-1.52.58,6.53,6.53,0,0,0,.36,5,10.22,10.22,0,0,0,4.26,4.92A13.31,13.31,0,0,1,223.47,183.79Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M223.47,183.79a13,13,0,0,1-3.1-8.15,2.11,2.11,0,0,0-1.52.58,6.53,6.53,0,0,0,.36,5,10.22,10.22,0,0,0,4.26,4.92A13.31,13.31,0,0,1,223.47,183.79Z"
          style="fill:#fff;opacity:0.5"
        ></path>
        <path
          d="M250.17,144.19v-18h-4.39v18h0a1.11,1.11,0,0,0,.65.9,3.45,3.45,0,0,0,3.1,0A1.12,1.12,0,0,0,250.17,144.19Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M245.78,129.23a8.11,8.11,0,0,0,2.16.3,8.23,8.23,0,0,0,2.23-.32v-3.07h-4.39Z"
          style="opacity:0.15"
        ></path>
        <circle cx="247.94" cy="119.42" r="8.14" style="fill:currentColor"></circle>
        <path
          d="M247.94,123.68c-5.87-1-7.89-6.25-5.3-10.44a8.14,8.14,0,1,0,13.44,6.18C255.73,122,253.15,124.6,247.94,123.68Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M247.62,116.68a12,12,0,0,1,3.59,1.7c.69.47,1.79,1.18,2.58.47s.58-2,.28-2.81a5.18,5.18,0,0,0-2.1-2.45,5.52,5.52,0,0,0-3.87-.71c-.84.15-2.22.58-2.45,1.55S246.72,116.42,247.62,116.68Z"
          style="fill:#fff;opacity:0.30000000000000004"
        ></path>
        <path
          d="M248,105.57a1,1,0,0,1-1-1v-7a1,1,0,0,1,2,0v7A1,1,0,0,1,248,105.57Z"
          style="fill:#e0e0e0"
        ></path>
        <path
          d="M255.39,107.58a1,1,0,0,1-.5-.13,1,1,0,0,1-.36-1.37L258,100a1,1,0,1,1,1.73,1l-3.51,6.08A1,1,0,0,1,255.39,107.58Z"
          style="fill:#e0e0e0"
        ></path>
        <path
          d="M260.81,113a1,1,0,0,1-.5-1.87l6.09-3.51a1,1,0,0,1,1,1.73l-6.09,3.52A1,1,0,0,1,260.81,113Z"
          style="fill:#e0e0e0"
        ></path>
        <path
          d="M269.81,120.46h-7a1,1,0,0,1,0-2h7a1,1,0,0,1,0,2Z"
          style="fill:#e0e0e0"
        ></path>
        <path
          d="M266.86,131.39a1,1,0,0,1-.5-.14l-6.09-3.51a1,1,0,1,1,1-1.73l6.08,3.51a1,1,0,0,1,.37,1.37A1,1,0,0,1,266.86,131.39Z"
          style="fill:#e0e0e0"
        ></path>
        <path
          d="M229,131.32a1,1,0,0,1-.5-1.87l6.08-3.51a1,1,0,0,1,1,1.74l-6.08,3.51A1,1,0,0,1,229,131.32Z"
          style="fill:#e0e0e0"
        ></path>
        <path
          d="M233.09,120.38h-7a1,1,0,0,1,0-2h7a1,1,0,1,1,0,2Z"
          style="fill:#e0e0e0"
        ></path>
        <path
          d="M235.1,113a1,1,0,0,1-.5-.13l-6.08-3.51a1,1,0,1,1,1-1.74l6.08,3.51a1,1,0,0,1,.37,1.37A1,1,0,0,1,235.1,113Z"
          style="fill:#e0e0e0"
        ></path>
        <path
          d="M240.55,107.54a1,1,0,0,1-.87-.5L236.17,101a1,1,0,1,1,1.73-1l3.52,6.08a1,1,0,0,1-.87,1.5Z"
          style="fill:#e0e0e0"
        ></path>
      </g>
    </g>
    <g id="freepik--error-404--inject-8">
      <g id="freepik--404--inject-8">
        <g id="freepik--shadow--inject-8">
          <path d="M171.44,82.27l3.23-1.87V74.15Z" style="fill:#e0e0e0"></path>
        </g>
        <g id="freepik--404--inject-8">
          <path
            d="M101.91,135.41a1.25,1.25,0,0,0-.24-.18l-7.14-4.12V97.24A2.3,2.3,0,0,0,94,95.51a.87.87,0,0,0-.22-.17L86.1,90.93A1,1,0,0,0,85,91L74.37,97.13a5,5,0,0,0-1.45,1.21,10.59,10.59,0,0,0-1.16,2.24l-21,52.94a14.28,14.28,0,0,0-.91,5.18v11.48a2.33,2.33,0,0,0,.56,1.73l.24.18,7.64,4.4a1,1,0,0,0,1.1-.07L73.87,168v2.22a2.3,2.3,0,0,0,.57,1.73.87.87,0,0,0,.22.17l7.65,4.41a1,1,0,0,0,1.11-.07l9.21-5.32A3.55,3.55,0,0,0,94,169.54a5.44,5.44,0,0,0,.57-2.38v-11l6-3.49a3.56,3.56,0,0,0,1.34-1.64,5.56,5.56,0,0,0,.56-2.38V137.14A2.33,2.33,0,0,0,101.91,135.41Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M80.52,102.74l0,.14A10.35,10.35,0,0,0,79.41,105l-21,53a12,12,0,0,0-.62,2.17l-7.7-4.44a13.65,13.65,0,0,1,.61-2.15l21-52.94a9.46,9.46,0,0,1,1.17-2.24,0,0,0,0,1,0,0Z"
            style="opacity:0.15"
          ></path>
          <path
            d="M58.18,176.44l-7.55-4.35a1.33,1.33,0,0,1-.24-.19,2.32,2.32,0,0,1-.56-1.73V158.71a15.36,15.36,0,0,1,.3-3l7.64,4.41a14.64,14.64,0,0,0-.29,3v11.47a2.38,2.38,0,0,0,.55,1.73Z"
            style="opacity:0.30000000000000004"
          ></path>
          <polygon
            points="81.88 126.2 81.88 146.11 75.44 142.4 81.88 126.2"
            style="opacity:0.30000000000000004"
          ></polygon>
          <polygon
            points="81.88 146.11 71.61 152.03 75.44 142.4 81.88 146.11"
            style="opacity:0.4"
          ></polygon>
          <path
            d="M82.08,176.4l.13.11-7.55-4.34a1.24,1.24,0,0,1-.22-.18,2.3,2.3,0,0,1-.57-1.73V168l7.65-4.41v11A2.33,2.33,0,0,0,82.08,176.4Z"
            style="opacity:0.30000000000000004"
          ></path>
          <path
            d="M101.58,135.19a1,1,0,0,0-1,.12l-6.06,3.49v-7.69S100.75,134.7,101.58,135.19Z"
            style="opacity:0.15"
          ></path>
          <path
            d="M93.69,95.31a1.1,1.1,0,0,0-1.07.1L82,101.54a4.87,4.87,0,0,0-1.44,1.2l-7.65-4.41a4.93,4.93,0,0,1,1.43-1.2L85,91a1,1,0,0,1,1.12-.07Z"
            style="fill:#fff;opacity:0.4"
          ></path>
          <path
            d="M83.42,176.5a1,1,0,0,1-1.34-.1,2.33,2.33,0,0,1-.56-1.73v-11L59.37,176.42a1,1,0,0,1-1.33-.1,2.29,2.29,0,0,1-.56-1.73V163.12a14.2,14.2,0,0,1,.91-5.18l21-52.95a10.38,10.38,0,0,1,1.16-2.24A5,5,0,0,1,82,101.54l10.62-6.13a1,1,0,0,1,1.33.1,2.3,2.3,0,0,1,.57,1.73V138.8l6-3.49a1,1,0,0,1,1.34.1,2.33,2.33,0,0,1,.56,1.73v11.47a5.56,5.56,0,0,1-.56,2.38,3.56,3.56,0,0,1-1.34,1.64l-6,3.49v11a5.44,5.44,0,0,1-.57,2.38,3.55,3.55,0,0,1-1.33,1.64Zm-1.55-30.39V126.2L71.61,152Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M151.19,89.85a47.43,47.43,0,0,0-1.44-10.37A16.08,16.08,0,0,0,146,72.27a8.94,8.94,0,0,0-1.92-1.51l-7.66-4.42a9.56,9.56,0,0,0-4.36-1.24Q128.29,64.92,123,68a33.56,33.56,0,0,0-9.07,7.62,45.36,45.36,0,0,0-6.29,10,53,53,0,0,0-3.72,11.52,74.5,74.5,0,0,0-1.45,12q-.14,4.74-.13,9.83t.13,9.44a48,48,0,0,0,1.45,10.37,16,16,0,0,0,3.72,7.21,8.77,8.77,0,0,0,1.91,1.5c1.28.76,7,4.08,7.78,4.49a9.38,9.38,0,0,0,4.25,1.18q3.8.18,9.07-2.86a33.37,33.37,0,0,0,9.07-7.61,44.83,44.83,0,0,0,6.29-10,52.81,52.81,0,0,0,3.73-11.52,73.37,73.37,0,0,0,1.44-12q.13-4.52.14-9.6T151.19,89.85Zm-27.78,38.47a30.78,30.78,0,0,1-.27-3.55q-.36-9,0-18.18a36.82,36.82,0,0,1,.49-5.26,22.92,22.92,0,0,1,1.27-4.68,15.1,15.1,0,0,1,2.25-4,11.93,11.93,0,0,1,3-2.72c.15,1.07.27,2.24.34,3.51q.27,8.82,0,18.18a41.35,41.35,0,0,1-.46,5.25,21.2,21.2,0,0,1-1.27,4.68,15.9,15.9,0,0,1-2.28,4A12.19,12.19,0,0,1,123.41,128.32Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M117.13,151.86c-1.27-.72-6.38-3.67-7.58-4.36a10,10,0,0,1-1.9-1.51,16,16,0,0,1-3.72-7.21,47.34,47.34,0,0,1-1.44-10.37c-.1-2.91-.14-6-.14-9.44s0-6.67.14-9.83a73.37,73.37,0,0,1,1.44-12,51,51,0,0,1,2.37-8.24l7.63,4.42a52.22,52.22,0,0,0-2.35,8.23,72.56,72.56,0,0,0-1.45,12c-.1,3.15-.14,6.43-.14,9.82s0,6.54.14,9.44a46.82,46.82,0,0,0,1.45,10.38,16,16,0,0,0,3.72,7.21A8.72,8.72,0,0,0,117.13,151.86Z"
            style="opacity:0.15"
          ></path>
          <path
            d="M143.81,70.6a9.68,9.68,0,0,0-4.08-1.08q-3.79-.18-9.07,2.85A33.44,33.44,0,0,0,121.59,80a44.54,44.54,0,0,0-6.29,10c-.26.55-.49,1.1-.73,1.67l-.63,1.61h0l-7.63-4.42c.18-.48.37-1,.56-1.43.26-.62.51-1.23.79-1.84a45.63,45.63,0,0,1,6.29-10A33.55,33.55,0,0,1,123,68c3.51-2,6.55-3,9.08-2.85a9.42,9.42,0,0,1,4.35,1.23C137.1,66.72,142.13,69.61,143.81,70.6Z"
            style="fill:#fff;opacity:0.4"
          ></path>
          <path
            d="M138.19,116.09a41.15,41.15,0,0,1-.47,5.24,20.51,20.51,0,0,1-1.26,4.68,15.32,15.32,0,0,1-.87,1.83L128,123.42a16,16,0,0,0,.86-1.82,21.2,21.2,0,0,0,1.27-4.68,39.77,39.77,0,0,0,.45-5.24q.28-9.36,0-18.19A35,35,0,0,0,130.2,90s.2-.14.46-.29a5.8,5.8,0,0,1,3.52-1A3.13,3.13,0,0,1,136.43,90a8.25,8.25,0,0,1,1.23,3.24,38.23,38.23,0,0,1,.53,4.68Q138.45,106.73,138.19,116.09Z"
            style="opacity:0.30000000000000004"
          ></path>
          <path
            d="M135.59,127.84a16.48,16.48,0,0,1-1.41,2.14,12,12,0,0,1-3.52,3,6,6,0,0,1-3.51,1.06,3.2,3.2,0,0,1-2.29-1.33,7.66,7.66,0,0,1-1.26-3.22c-.08-.37-.13-.77-.19-1.17a12,12,0,0,0,3.11-2.75,15.74,15.74,0,0,0,1.43-2.15Z"
            style="opacity:0.4"
          ></path>
          <path
            d="M130.66,72.37q5.26-3,9.07-2.85A9.08,9.08,0,0,1,146,72.27a16.08,16.08,0,0,1,3.73,7.21,47.43,47.43,0,0,1,1.44,10.37q.13,4.58.14,9.67t-.14,9.6a73.37,73.37,0,0,1-1.44,12A52.81,52.81,0,0,1,146,132.67a44.83,44.83,0,0,1-6.29,10,33.37,33.37,0,0,1-9.07,7.61q-5.26,3-9.07,2.86a9.08,9.08,0,0,1-6.29-2.75,16.07,16.07,0,0,1-3.73-7.22,47.26,47.26,0,0,1-1.44-10.37q-.13-4.35-.14-9.44t.14-9.82a73.61,73.61,0,0,1,1.44-12A52.9,52.9,0,0,1,115.3,90a44.83,44.83,0,0,1,6.29-10A33.41,33.41,0,0,1,130.66,72.37Zm7.52,43.72q.28-9.36,0-18.19a36.73,36.73,0,0,0-.52-4.67A8.16,8.16,0,0,0,136.42,90a3.06,3.06,0,0,0-2.25-1.35,6,6,0,0,0-3.51,1,11.82,11.82,0,0,0-3.51,3,15.1,15.1,0,0,0-2.25,4,22.92,22.92,0,0,0-1.27,4.68,36.82,36.82,0,0,0-.49,5.26q-.36,9.19,0,18.18a30.15,30.15,0,0,0,.45,4.72,7.66,7.66,0,0,0,1.27,3.22,3.24,3.24,0,0,0,2.29,1.33,6,6,0,0,0,3.51-1,12,12,0,0,0,3.51-3,16.06,16.06,0,0,0,2.29-4,20.9,20.9,0,0,0,1.27-4.68A43.14,43.14,0,0,0,138.18,116.09Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M205.13,75.81a1.3,1.3,0,0,0-.23-.18c-.33-.18-7.15-4.12-7.15-4.12V37.64a2.33,2.33,0,0,0-.56-1.73,1.24,1.24,0,0,0-.23-.17l-7.63-4.41a1.07,1.07,0,0,0-1.12.07l-10.62,6.13a4.87,4.87,0,0,0-1.44,1.21A10.14,10.14,0,0,0,175,41L154,93.92a14.29,14.29,0,0,0-.91,5.19v11.47a2.29,2.29,0,0,0,.56,1.73,1.08,1.08,0,0,0,.24.18l7.63,4.41a1.05,1.05,0,0,0,1.11-.08l14.5-8.37v2.21a2.33,2.33,0,0,0,.56,1.73.91.91,0,0,0,.23.17l7.64,4.42a1.05,1.05,0,0,0,1.11-.08l9.21-5.32a3.53,3.53,0,0,0,1.34-1.63,5.64,5.64,0,0,0,.56-2.39v-11L203.8,93a3.52,3.52,0,0,0,1.33-1.63,5.48,5.48,0,0,0,.56-2.38V77.54A2.29,2.29,0,0,0,205.13,75.81Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M183.74,43.15l0,.13a11,11,0,0,0-1.08,2.12l-21,52.94a11.26,11.26,0,0,0-.62,2.17l-7.7-4.44a12.92,12.92,0,0,1,.61-2.15l21-52.94a9.72,9.72,0,0,1,1.16-2.24,0,0,0,0,1,0,0Z"
            style="opacity:0.15"
          ></path>
          <path
            d="M161.4,116.84l-7.55-4.35a1.21,1.21,0,0,1-.23-.18,2.31,2.31,0,0,1-.57-1.74V99.11a15.44,15.44,0,0,1,.3-3l7.65,4.41a14.63,14.63,0,0,0-.3,3V115a2.31,2.31,0,0,0,.56,1.72Z"
            style="opacity:0.30000000000000004"
          ></path>
          <polygon
            points="185.1 66.6 185.1 86.52 178.66 82.8 185.1 66.6"
            style="opacity:0.30000000000000004"
          ></polygon>
          <polygon
            points="185.1 86.52 174.83 92.44 178.66 82.8 185.1 86.52"
            style="opacity:0.4"
          ></polygon>
          <path
            d="M185.31,116.8l.13.12-7.55-4.35a1,1,0,0,1-.23-.18,2.33,2.33,0,0,1-.56-1.73v-2.21l7.64-4.42v11A2.28,2.28,0,0,0,185.31,116.8Z"
            style="opacity:0.30000000000000004"
          ></path>
          <path
            d="M204.81,75.59a1.1,1.1,0,0,0-1,.12l-6.05,3.49V71.51Z"
            style="opacity:0.15"
          ></path>
          <path
            d="M196.91,35.71a1.06,1.06,0,0,0-1.06.11l-10.61,6.12a5.16,5.16,0,0,0-1.44,1.21l-7.64-4.42a5.09,5.09,0,0,1,1.43-1.2l10.62-6.13a1,1,0,0,1,1.12-.06C189.4,31.37,196.2,35.3,196.91,35.71Z"
            style="fill:#fff;opacity:0.4"
          ></path>
          <path
            d="M186.64,116.9a1,1,0,0,1-1.33-.09,2.32,2.32,0,0,1-.56-1.74V104L162.6,116.82a1,1,0,0,1-1.34-.1,2.32,2.32,0,0,1-.56-1.73V103.52a14.24,14.24,0,0,1,.92-5.18l21-52.94a9.51,9.51,0,0,1,1.16-2.24A4.72,4.72,0,0,1,185.24,42l10.61-6.13a1,1,0,0,1,1.34.09,2.33,2.33,0,0,1,.56,1.73V79.21l6.05-3.49a1,1,0,0,1,1.33.09,2.29,2.29,0,0,1,.56,1.73V89a5.48,5.48,0,0,1-.56,2.38A3.52,3.52,0,0,1,203.8,93l-6.05,3.5v11a5.64,5.64,0,0,1-.56,2.39,3.53,3.53,0,0,1-1.34,1.63ZM185.1,86.51V66.6L174.83,92.44Z"
            style="fill:currentColor"
          ></path>
        </g>
        <path
          d="M106.61,191.27a.31.31,0,0,1,.39,0,.7.7,0,0,1,.16.5v3.28a1.69,1.69,0,0,1-.16.68,1,1,0,0,1-.39.47l-9.53,5.51a.3.3,0,0,1-.38,0,.65.65,0,0,1-.16-.5v-20a1.57,1.57,0,0,1,.16-.68,1,1,0,0,1,.38-.47l9.37-5.41a.3.3,0,0,1,.39,0,.69.69,0,0,1,.16.49v3.29a1.73,1.73,0,0,1-.16.68,1,1,0,0,1-.39.47l-6.19,3.57v3.35L106,183.2a.29.29,0,0,1,.38,0,.67.67,0,0,1,.17.5V187a1.52,1.52,0,0,1-.17.68.93.93,0,0,1-.38.47l-5.75,3.32v3.47Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M116,169.15a7.17,7.17,0,0,1,2.37-.94,2.6,2.6,0,0,1,1.78.27,2.84,2.84,0,0,1,1.13,1.57,9,9,0,0,1,.39,2.92,13.59,13.59,0,0,1-.62,4.21,11.22,11.22,0,0,1-1.77,3.36l2.49,5.93a.85.85,0,0,1,.06.31,1.3,1.3,0,0,1-.13.56.81.81,0,0,1-.31.38l-2.73,1.58c-.27.15-.46.17-.58,0a1.76,1.76,0,0,1-.27-.4l-2.23-5.34-1.85,1.07v6.63a1.73,1.73,0,0,1-.16.68,1,1,0,0,1-.39.46L110.52,194a.3.3,0,0,1-.38,0,.66.66,0,0,1-.17-.5v-20a1.59,1.59,0,0,1,.17-.68,1,1,0,0,1,.38-.47Zm-2.27,10.69,2.27-1.31a4.24,4.24,0,0,0,1.4-1.3,3.69,3.69,0,0,0,.55-2.12c0-.86-.18-1.36-.55-1.47a1.76,1.76,0,0,0-1.4.31l-2.27,1.31Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M130.46,160.78a7,7,0,0,1,2.37-.93,2.49,2.49,0,0,1,1.78.27,2.81,2.81,0,0,1,1.13,1.56,9.08,9.08,0,0,1,.39,2.92,13.6,13.6,0,0,1-.62,4.22,11.22,11.22,0,0,1-1.77,3.36l2.49,5.93a.81.81,0,0,1,.06.31,1.23,1.23,0,0,1-.13.55.82.82,0,0,1-.31.38l-2.74,1.58c-.27.16-.46.17-.57,0a1.18,1.18,0,0,1-.27-.4L130,175.24l-1.85,1.07v6.63a1.51,1.51,0,0,1-.16.68,1,1,0,0,1-.38.47L125,185.61a.29.29,0,0,1-.38,0,.67.67,0,0,1-.16-.5v-20a1.54,1.54,0,0,1,.16-.68,1.1,1.1,0,0,1,.38-.47Zm-2.28,10.7,2.28-1.31a4.22,4.22,0,0,0,1.39-1.3,3.71,3.71,0,0,0,.56-2.12c0-.87-.19-1.36-.56-1.48a1.79,1.79,0,0,0-1.39.31l-2.28,1.32Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M138.48,164.23a17.45,17.45,0,0,1,.5-3.55,15.38,15.38,0,0,1,1.22-3.37,14.42,14.42,0,0,1,1.93-2.91,10.16,10.16,0,0,1,2.63-2.18,5.33,5.33,0,0,1,2.62-.85,2.91,2.91,0,0,1,1.94.68,4.3,4.3,0,0,1,1.22,1.95,10.48,10.48,0,0,1,.5,3c0,.87,0,1.79,0,2.76s0,1.89,0,2.75a17.45,17.45,0,0,1-.5,3.55,16.29,16.29,0,0,1-1.22,3.39,13.86,13.86,0,0,1-1.94,2.92,10.6,10.6,0,0,1-2.62,2.16,5.45,5.45,0,0,1-2.63.86,2.8,2.8,0,0,1-1.93-.68,4.29,4.29,0,0,1-1.22-2,10.47,10.47,0,0,1-.5-3c0-.83,0-1.73,0-2.7S138.45,165.14,138.48,164.23Zm8.83.26c0-.38,0-.8.05-1.26s0-.93,0-1.41,0-.93,0-1.36,0-.83-.05-1.17a4.78,4.78,0,0,0-.21-1.3,2,2,0,0,0-.48-.86,1.13,1.13,0,0,0-.77-.31,2.25,2.25,0,0,0-1.09.35,4,4,0,0,0-1.09.91,5.34,5.34,0,0,0-.77,1.2,6.51,6.51,0,0,0-.48,1.41,8.77,8.77,0,0,0-.22,1.55c0,.37,0,.77,0,1.22s0,.91,0,1.39,0,.93,0,1.38,0,.86,0,1.21a3.44,3.44,0,0,0,.7,2.17c.41.45,1,.43,1.86-.05a4.39,4.39,0,0,0,1.85-2.09A8.51,8.51,0,0,0,147.31,164.49Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M159.89,143.79a7.4,7.4,0,0,1,2.37-.94,2.55,2.55,0,0,1,1.78.28,2.78,2.78,0,0,1,1.13,1.56,9.08,9.08,0,0,1,.39,2.92,13.54,13.54,0,0,1-.62,4.21,11.16,11.16,0,0,1-1.77,3.37l2.49,5.93a.8.8,0,0,1,.06.3,1.27,1.27,0,0,1-.13.56.87.87,0,0,1-.31.38l-2.74,1.58c-.26.15-.46.17-.57.05a1.56,1.56,0,0,1-.27-.4l-2.23-5.34-1.85,1.07V166a1.55,1.55,0,0,1-.17.68,1,1,0,0,1-.38.47l-2.63,1.52a.29.29,0,0,1-.39,0,.69.69,0,0,1-.16-.49v-20a1.65,1.65,0,0,1,.16-.68,1,1,0,0,1,.39-.47Zm-2.27,10.7,2.27-1.31a4.27,4.27,0,0,0,1.4-1.31,3.68,3.68,0,0,0,.55-2.11c0-.87-.18-1.36-.55-1.48a1.8,1.8,0,0,0-1.4.31l-2.27,1.31Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M70.33,227.89c1.25-.72,2.22-.82,2.9-.32s1,1.63,1,3.37a9.7,9.7,0,0,1-1,4.58,7.3,7.3,0,0,1-2.9,3L67.87,240v5.29a1.11,1.11,0,0,1-.13.52.72.72,0,0,1-.29.36l-1.25.72a.21.21,0,0,1-.29,0,.52.52,0,0,1-.12-.38V231.16a1.28,1.28,0,0,1,.12-.53.75.75,0,0,1,.29-.35ZM67.87,237l2.39-1.38a4.09,4.09,0,0,0,1.4-1.34,4,4,0,0,0,.52-2.17c0-.89-.17-1.4-.52-1.54a1.66,1.66,0,0,0-1.4.3l-2.39,1.38Z"
          style="fill:#e0e0e0"
        ></path>
        <path
          d="M80.76,221.87c.16-.09.28-.09.37,0a1.21,1.21,0,0,1,.21.48l3.78,12.82a.76.76,0,0,1,0,.16,1.23,1.23,0,0,1-.12.52.73.73,0,0,1-.29.36l-1.11.64c-.21.12-.35.14-.43,0a.62.62,0,0,1-.17-.29l-.67-2.26-4.87,2.82-.66,3a2.39,2.39,0,0,1-.17.49.94.94,0,0,1-.43.45l-1.11.65a.23.23,0,0,1-.3,0,.49.49,0,0,1-.12-.38.88.88,0,0,1,0-.17L78.47,224a4.07,4.07,0,0,1,.22-.73,1,1,0,0,1,.37-.42Zm.84,9.93L79.91,226l-1.7,7.74Z"
          style="fill:#e0e0e0"
        ></path>
        <path
          d="M86.32,224.81a14.39,14.39,0,0,1,.34-2.8,11.17,11.17,0,0,1,.9-2.53A9.56,9.56,0,0,1,89,217.34a8,8,0,0,1,2-1.62,4.52,4.52,0,0,1,2.22-.74,2.13,2.13,0,0,1,1.38.48,2.39,2.39,0,0,1,.73,1.13,5.08,5.08,0,0,1,.24,1.2,1,1,0,0,1-.12.53.82.82,0,0,1-.3.35l-1.25.72q-.17.11-.27,0a.45.45,0,0,1-.14-.19s-.08-.16-.17-.32a1.05,1.05,0,0,0-.41-.43,1.39,1.39,0,0,0-.75-.18,2.42,2.42,0,0,0-1.16.39,4.82,4.82,0,0,0-1.85,2,7.14,7.14,0,0,0-.74,3c0,.62,0,1.27,0,2s0,1.33,0,1.91c0,1.3.28,2.11.74,2.44a1.69,1.69,0,0,0,1.85-.18,5.4,5.4,0,0,0,1.05-.8,4.55,4.55,0,0,0,.83-1.09,5.82,5.82,0,0,0,.54-1.44,8.43,8.43,0,0,0,.19-1.83v-.19l-1.88,1.08a.23.23,0,0,1-.3,0,.49.49,0,0,1-.12-.38V223.5a1.23,1.23,0,0,1,.12-.52.74.74,0,0,1,.3-.36l3.54-2.05a.24.24,0,0,1,.3,0,.52.52,0,0,1,.12.38v2.61a13.62,13.62,0,0,1-.29,2.79,11.54,11.54,0,0,1-.88,2.58A9.55,9.55,0,0,1,93,231.14,7.74,7.74,0,0,1,91,232.81a4.46,4.46,0,0,1-2,.71,2,2,0,0,1-1.44-.51,3.38,3.38,0,0,1-.87-1.55,9.39,9.39,0,0,1-.33-2.43c0-.29,0-.62,0-1s0-.73,0-1.11,0-.76,0-1.12S86.31,225.11,86.32,224.81Z"
          style="fill:#e0e0e0"
        ></path>
        <path
          d="M105.19,221.41a.24.24,0,0,1,.29,0,.49.49,0,0,1,.13.38v1.69a1.11,1.11,0,0,1-.13.52.73.73,0,0,1-.29.36l-6.76,3.91a.24.24,0,0,1-.3,0,.52.52,0,0,1-.12-.38V212.55a1.27,1.27,0,0,1,.12-.52.79.79,0,0,1,.3-.36l6.64-3.83a.21.21,0,0,1,.29,0,.51.51,0,0,1,.12.38v1.68a1.2,1.2,0,0,1-.12.52.73.73,0,0,1-.29.36l-5,2.88v3.77l4.64-2.68a.24.24,0,0,1,.29,0,.49.49,0,0,1,.13.38v1.69a1.11,1.11,0,0,1-.13.52.73.73,0,0,1-.29.36l-4.64,2.68v3.94Z"
          style="fill:#e0e0e0"
        ></path>
        <path
          d="M118.81,216.52c-.18.1-.31.11-.41,0l-.2-.22-4.31-7.38v9.78a1.11,1.11,0,0,1-.13.52.73.73,0,0,1-.29.36l-1.25.72a.21.21,0,0,1-.29,0,.51.51,0,0,1-.12-.38V204.59a1.28,1.28,0,0,1,.12-.53.75.75,0,0,1,.29-.35l1.08-.63c.18-.1.31-.1.4,0a1.2,1.2,0,0,1,.2.21l4.32,7.38v-9.77a1.28,1.28,0,0,1,.12-.53.85.85,0,0,1,.3-.36l1.24-.72a.24.24,0,0,1,.3,0,.52.52,0,0,1,.12.38V215a1.23,1.23,0,0,1-.12.52.85.85,0,0,1-.3.36Z"
          style="fill:#e0e0e0"
        ></path>
        <path
          d="M122.72,203.84a15.33,15.33,0,0,1,.33-2.69,11.56,11.56,0,0,1,.84-2.53,10.09,10.09,0,0,1,1.39-2.18,7.35,7.35,0,0,1,2-1.65,3.9,3.9,0,0,1,2-.63,1.93,1.93,0,0,1,1.39.57,3.69,3.69,0,0,1,.85,1.56,9.56,9.56,0,0,1,.34,2.32c0,.29,0,.62,0,1s0,.74,0,1.12,0,.77,0,1.13,0,.71,0,1a15.33,15.33,0,0,1-.33,2.7,11.76,11.76,0,0,1-.85,2.53,9.66,9.66,0,0,1-1.39,2.17,7.67,7.67,0,0,1-2,1.63,4,4,0,0,1-2,.64,2,2,0,0,1-1.39-.56,3.46,3.46,0,0,1-.84-1.56,9.73,9.73,0,0,1-.33-2.31c0-.3,0-.62,0-1s0-.74,0-1.13,0-.76,0-1.13S122.71,204.15,122.72,203.84Zm7-3.91a3.14,3.14,0,0,0-.72-2.24,1.41,1.41,0,0,0-1.73.07,4.36,4.36,0,0,0-1.74,1.94,7.26,7.26,0,0,0-.72,3.06c0,.61,0,1.28,0,2s0,1.4,0,2a3,3,0,0,0,.72,2.22c.44.36,1,.34,1.74-.08A4.46,4.46,0,0,0,129,207a7.57,7.57,0,0,0,.72-3.05c0-.61,0-1.29,0-2S129.73,200.52,129.71,199.93Z"
          style="fill:#e0e0e0"
        ></path>
        <path
          d="M141.17,187a.24.24,0,0,1,.3,0,.52.52,0,0,1,.12.38v1.69a1.23,1.23,0,0,1-.12.52.85.85,0,0,1-.3.36l-2.77,1.6v13a1.23,1.23,0,0,1-.12.52.85.85,0,0,1-.3.36l-1.25.72a.22.22,0,0,1-.29,0,.52.52,0,0,1-.12-.38v-13l-2.78,1.6a.21.21,0,0,1-.29,0,.51.51,0,0,1-.12-.38v-1.68a1.28,1.28,0,0,1,.12-.53.75.75,0,0,1,.29-.35Z"
          style="fill:#e0e0e0"
        ></path>
        <path
          d="M154,179.57a.23.23,0,0,1,.3,0,.51.51,0,0,1,.12.38v1.68a1.28,1.28,0,0,1-.12.53.76.76,0,0,1-.3.35l-4.92,2.84V190l4.61-2.66a.23.23,0,0,1,.3,0,.51.51,0,0,1,.12.38v1.68a1.2,1.2,0,0,1-.12.52.69.69,0,0,1-.3.36L149.11,193v5.43a1.2,1.2,0,0,1-.12.52.73.73,0,0,1-.29.36l-1.25.72a.21.21,0,0,1-.29,0,.47.47,0,0,1-.13-.38V184.25a1.11,1.11,0,0,1,.13-.52.73.73,0,0,1,.29-.36Z"
          style="fill:#e0e0e0"
        ></path>
        <path
          d="M156.13,184.55a15.33,15.33,0,0,1,.33-2.69,11.56,11.56,0,0,1,.84-2.53,9.73,9.73,0,0,1,1.39-2.18,7.31,7.31,0,0,1,2-1.65,3.93,3.93,0,0,1,2-.63,1.9,1.9,0,0,1,1.39.58,3.59,3.59,0,0,1,.85,1.55,9.56,9.56,0,0,1,.34,2.32c0,.29,0,.62,0,1s0,.74,0,1.12,0,.77,0,1.14,0,.7,0,1a15.35,15.35,0,0,1-.34,2.69,11.56,11.56,0,0,1-.84,2.53,9.66,9.66,0,0,1-1.39,2.17,7.55,7.55,0,0,1-2,1.63,4,4,0,0,1-2,.64,2,2,0,0,1-1.39-.56,3.41,3.41,0,0,1-.84-1.56,9.73,9.73,0,0,1-.33-2.31c0-.29,0-.62,0-1s0-.74,0-1.13,0-.76,0-1.13S156.12,184.86,156.13,184.55Zm7-3.91a3.06,3.06,0,0,0-.71-2.23q-.66-.57-1.74.06a4.4,4.4,0,0,0-1.73,1.94,7.34,7.34,0,0,0-.72,3.06c0,.61,0,1.29,0,2s0,1.4,0,2a3,3,0,0,0,.72,2.22c.43.37,1,.34,1.73-.07a4.51,4.51,0,0,0,1.74-1.93,7.4,7.4,0,0,0,.71-3c0-.61,0-1.29,0-2S163.13,181.23,163.11,180.64Z"
          style="fill:#e0e0e0"
        ></path>
        <path
          d="M176,166.91a.21.21,0,0,1,.29,0,.52.52,0,0,1,.12.38V177a13.62,13.62,0,0,1-.28,2.82,10.8,10.8,0,0,1-.83,2.52,8.73,8.73,0,0,1-1.39,2.12,7.5,7.5,0,0,1-1.92,1.58,4.26,4.26,0,0,1-1.94.65,1.85,1.85,0,0,1-1.38-.52,3.26,3.26,0,0,1-.82-1.56,10.09,10.09,0,0,1-.27-2.51v-9.73a1.23,1.23,0,0,1,.12-.52.84.84,0,0,1,.29-.36l1.25-.72a.23.23,0,0,1,.3,0,.52.52,0,0,1,.12.38v9.61a6,6,0,0,0,.17,1.57,1.69,1.69,0,0,0,.48.85,1,1,0,0,0,.74.21,2.4,2.4,0,0,0,.94-.34,4.75,4.75,0,0,0,1-.75,4.15,4.15,0,0,0,.74-1.07,6,6,0,0,0,.49-1.41,7.94,7.94,0,0,0,.18-1.76V168.5a1.27,1.27,0,0,1,.12-.52.84.84,0,0,1,.29-.36Z"
          style="fill:#e0e0e0"
        ></path>
        <path
          d="M186.09,177.68c-.18.1-.31.1-.41,0a1.77,1.77,0,0,1-.2-.21l-4.31-7.38v9.78a1.11,1.11,0,0,1-.13.52.84.84,0,0,1-.29.36l-1.25.72a.22.22,0,0,1-.29,0,.52.52,0,0,1-.12-.38V165.74a1.27,1.27,0,0,1,.12-.52.84.84,0,0,1,.29-.36l1.08-.62c.18-.1.31-.11.4,0a1.72,1.72,0,0,1,.2.22l4.32,7.38V162a1.23,1.23,0,0,1,.12-.52.78.78,0,0,1,.29-.36l1.25-.72a.23.23,0,0,1,.3,0,.52.52,0,0,1,.12.38v15.33a1.2,1.2,0,0,1-.12.52.74.74,0,0,1-.3.36Z"
          style="fill:#e0e0e0"
        ></path>
        <path
          d="M194.59,156.15a4.35,4.35,0,0,1,2-.68,1.85,1.85,0,0,1,1.4.51,3.41,3.41,0,0,1,.86,1.56,10.7,10.7,0,0,1,.36,2.5q.08,1.72,0,3.51a17,17,0,0,1-.36,2.92A12,12,0,0,1,198,169a9.15,9.15,0,0,1-1.38,2.11,7.64,7.64,0,0,1-1.94,1.58L190.79,175a.23.23,0,0,1-.3,0,.51.51,0,0,1-.12-.38V159.23a1.2,1.2,0,0,1,.12-.52.74.74,0,0,1,.3-.36Zm2.53,5.09a5.41,5.41,0,0,0-.19-1.3,1.92,1.92,0,0,0-.48-.86,1,1,0,0,0-.79-.3,2.42,2.42,0,0,0-1.14.38l-2.07,1.19V171l2.14-1.23a4.24,4.24,0,0,0,1.1-.91,5.28,5.28,0,0,0,.77-1.19,6.49,6.49,0,0,0,.47-1.41,8.88,8.88,0,0,0,.19-1.54Q197.18,163,197.12,161.24Z"
          style="fill:#e0e0e0"
        ></path>
      </g>
    </g>
    <g id="freepik--speech-bubble--inject-8">
      <g id="freepik--speech-bubble--inject-8">
        <g id="freepik--speech-bubble--inject-8">
          <path
            d="M397.11,85.78,321,129.65a3.14,3.14,0,0,0-1.43,2.48v45.16a3.13,3.13,0,0,0,1.44,2.47l5.13,2.87a3.22,3.22,0,0,0,2.87,0l76.08-43.87a3.18,3.18,0,0,0,1.43-2.48V91.1a3.11,3.11,0,0,0-1.44-2.46L400,85.76A3.19,3.19,0,0,0,397.11,85.78Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M327.61,181.79V136.63a3.16,3.16,0,0,1,1.43-2.48l76.08-43.87c.79-.46,1.43-.09,1.43.82v45.17a3.18,3.18,0,0,1-1.43,2.48L329,182.62C328.25,183.07,327.61,182.7,327.61,181.79Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M329,182.62a3.12,3.12,0,0,1-2.87,0L321,179.75a3.1,3.1,0,0,1-1.44-2.46V132.13a3,3,0,0,1,.45-1.46l8,4.55a2.88,2.88,0,0,0-.42,1.41v45.16C327.61,182.62,328.2,183.11,329,182.62Z"
            style="opacity:0.2"
          ></path>
          <path
            d="M406.53,90.84c-.11-.72-.7-1-1.41-.56L329,134.15a2.9,2.9,0,0,0-1,1.07l-8-4.55a2.83,2.83,0,0,1,1-1l76.08-43.88a3.22,3.22,0,0,1,2.87,0l5.13,2.88A3.15,3.15,0,0,1,406.53,90.84Z"
            style="fill:#fff;opacity:0.4"
          ></path>
          <path
            d="M319.6,151.82l-11.52,11.72a1.27,1.27,0,0,0,.21,2c.24.15,8,4.59,8,4.59l11.34-13.66Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M319.6,151.82l-11.52,11.72a1.27,1.27,0,0,0,.21,2c.24.15,8,4.59,8,4.59l11.34-13.66Z"
            style="opacity:0.1"
          ></path>
          <path
            d="M327.61,156.43l-11.52,11.72a1.27,1.27,0,0,0,1.08,2.15l10.44-1.51C328.73,168.79,328.73,156.43,327.61,156.43Z"
            style="fill:currentColor"
          ></path>
        </g>
        <path
          d="M335.15,148.72a20.79,20.79,0,0,1,.56-4,18.32,18.32,0,0,1,1.39-3.82,16,16,0,0,1,2.19-3.3,11.39,11.39,0,0,1,3-2.47,6.07,6.07,0,0,1,3-1,3.33,3.33,0,0,1,2.2.77,4.78,4.78,0,0,1,1.39,2.21,11.64,11.64,0,0,1,.56,3.38c0,1,.05,2,.05,3.14s0,2.14-.05,3.11a19.36,19.36,0,0,1-.56,4,17.94,17.94,0,0,1-1.39,3.84,15.5,15.5,0,0,1-2.2,3.31,11.68,11.68,0,0,1-3,2.44,6.18,6.18,0,0,1-3,1,3.17,3.17,0,0,1-2.19-.77,5,5,0,0,1-1.39-2.23,12.36,12.36,0,0,1-.56-3.38c0-.94,0-2,0-3.07S335.12,149.74,335.15,148.72Zm10,.29c0-.43,0-.9,0-1.43s0-1.05,0-1.59,0-1.05,0-1.55,0-.93,0-1.32a6.1,6.1,0,0,0-.24-1.48,2.3,2.3,0,0,0-.54-1,1.31,1.31,0,0,0-.88-.35,2.35,2.35,0,0,0-1.23.4,4.65,4.65,0,0,0-1.23,1,6.16,6.16,0,0,0-.88,1.37,7.55,7.55,0,0,0-.55,1.6,10,10,0,0,0-.24,1.75q0,.63-.06,1.38c0,.51,0,1,0,1.57s0,1.07,0,1.58,0,1,.06,1.37a3.88,3.88,0,0,0,.79,2.46c.46.5,1.16.48,2.11-.06a5,5,0,0,0,2.09-2.37A9.63,9.63,0,0,0,345.16,149Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M363.9,137.07c0,.34,0,.79,0,1.34s0,1,0,1.36a14.82,14.82,0,0,1-.5,3.45,16.47,16.47,0,0,1-1.25,3.31,14,14,0,0,1-1.93,2.85,9.91,9.91,0,0,1-2.52,2.08,5.23,5.23,0,0,1-2.52.82,2.83,2.83,0,0,1-1.92-.62A4.23,4.23,0,0,1,352,149.8a8.88,8.88,0,0,1-.5-2.87c0-.34,0-.78,0-1.33s0-1,0-1.37a14.61,14.61,0,0,1,.48-3.43,15.49,15.49,0,0,1,1.27-3.32,14.42,14.42,0,0,1,1.93-2.87,9.76,9.76,0,0,1,2.52-2.1,5.13,5.13,0,0,1,2.52-.81,3,3,0,0,1,1.94.64,4,4,0,0,1,1.25,1.85A9.06,9.06,0,0,1,363.9,137.07Zm-8.29,7.32a2.27,2.27,0,0,0,.62,1.73,1.28,1.28,0,0,0,1.47-.1,3.76,3.76,0,0,0,1.47-1.6,5.88,5.88,0,0,0,.63-2.45c0-.37.05-.78.05-1.22s0-.84-.05-1.17a2.27,2.27,0,0,0-.63-1.73c-.37-.29-.86-.25-1.47.1a3.76,3.76,0,0,0-1.47,1.6,5.87,5.87,0,0,0-.62,2.45,11.59,11.59,0,0,0-.05,1.22A10.09,10.09,0,0,0,355.61,144.39Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M373.44,142.38a3.6,3.6,0,0,1-1.17.46,2,2,0,0,1-.86,0,1.54,1.54,0,0,1-.62-.33,2.38,2.38,0,0,1-.41-.44v7.47a1.85,1.85,0,0,1-.18.78,1.19,1.19,0,0,1-.44.53l-2.87,1.66a.34.34,0,0,1-.43,0,.78.78,0,0,1-.18-.57v-23a1.85,1.85,0,0,1,.18-.78,1.18,1.18,0,0,1,.43-.53l2.76-1.59a.32.32,0,0,1,.43,0,.76.76,0,0,1,.19.56v.81c.13-.31.28-.65.44-1a7.07,7.07,0,0,1,.59-1,6.57,6.57,0,0,1,.87-1,6.23,6.23,0,0,1,1.27-.93,4.35,4.35,0,0,1,1.93-.64,2.14,2.14,0,0,1,1.6.55,4.28,4.28,0,0,1,1.11,1.87,11.64,11.64,0,0,1,.46,3.31c0,.35,0,.81,0,1.41s0,1.08,0,1.44a18.21,18.21,0,0,1-.46,3.85,15.51,15.51,0,0,1-1.11,3.15,10.63,10.63,0,0,1-1.6,2.41A8.14,8.14,0,0,1,373.44,142.38Zm1-9.09a12.82,12.82,0,0,0,0-2,3.5,3.5,0,0,0-.52-1.84q-.46-.66-1.53,0a3.09,3.09,0,0,0-.87.74,4.82,4.82,0,0,0-.6,1,6.32,6.32,0,0,0-.38,1.11,7.58,7.58,0,0,0-.18,1.13c0,.36-.05.78-.05,1.25s0,.87.05,1.2a4.17,4.17,0,0,0,.18.92,1.81,1.81,0,0,0,.38.67.87.87,0,0,0,.6.3,1.48,1.48,0,0,0,.87-.25,3.5,3.5,0,0,0,1.53-1.79A7.89,7.89,0,0,0,374.46,133.29Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M387.66,128.34c0-.26-.05-.43-.17-.5a.77.77,0,0,0-.58,0,10.65,10.65,0,0,0-1.08.35c-.45.16-1,.35-1.68.54a2.54,2.54,0,0,1-2.67-.42,4.66,4.66,0,0,1-.93-3.32,8.12,8.12,0,0,1,.39-2.44,11.49,11.49,0,0,1,1.08-2.41,10.94,10.94,0,0,1,1.68-2.18,10.28,10.28,0,0,1,2.18-1.71,6.34,6.34,0,0,1,2.19-.82,3.76,3.76,0,0,1,1.74.12,2.27,2.27,0,0,1,1.15.92,3,3,0,0,1,.47,1.5,1.6,1.6,0,0,1-.18.77,1.15,1.15,0,0,1-.44.53l-2.71,1.57c-.17.1-.3.12-.4.06a3,3,0,0,0-.36-.19,1.17,1.17,0,0,0-.53-.07,2.42,2.42,0,0,0-.93.35,3.16,3.16,0,0,0-.87.74,1.47,1.47,0,0,0-.36,1,.86.86,0,0,0,.12.53c.07.1.23.12.45.07a9.62,9.62,0,0,0,1-.29l1.62-.55c1.38-.45,2.39-.31,3,.42a4.71,4.71,0,0,1,.95,3.19,8.28,8.28,0,0,1-.39,2.45,10.81,10.81,0,0,1-1.14,2.48,12.35,12.35,0,0,1-1.82,2.3,11.87,11.87,0,0,1-2.44,1.87,6.5,6.5,0,0,1-2.41.91,3.11,3.11,0,0,1-1.74-.2,2.38,2.38,0,0,1-1.09-1,3.31,3.31,0,0,1-.42-1.48,1.53,1.53,0,0,1,.17-.76,1.25,1.25,0,0,1,.45-.54l2.82-1.63c.17-.1.29-.1.37,0a1.11,1.11,0,0,0,.3.25.93.93,0,0,0,.52.12,2.48,2.48,0,0,0,1-.4,4.44,4.44,0,0,0,1.18-1A1.66,1.66,0,0,0,387.66,128.34Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M398.17,119.25a1.77,1.77,0,0,1-.18.77,1.08,1.08,0,0,1-.43.53l-2.88,1.66a.32.32,0,0,1-.43,0,.75.75,0,0,1-.18-.56V106.49a1.77,1.77,0,0,1,.18-.77,1.13,1.13,0,0,1,.43-.53l2.88-1.66a.32.32,0,0,1,.43,0,.75.75,0,0,1,.18.56Zm0,7.55a1.8,1.8,0,0,1-.18.77,1.13,1.13,0,0,1-.43.53l-2.88,1.66a.32.32,0,0,1-.43,0,.75.75,0,0,1-.18-.56v-3.55a1.8,1.8,0,0,1,.18-.77,1.13,1.13,0,0,1,.43-.53l2.88-1.66a.32.32,0,0,1,.43,0,.75.75,0,0,1,.18.56Z"
          style="fill:#455a64"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
